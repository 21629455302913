<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title p-d-flex p-ai-center" v-if="itemData?.id">
        <div>Edit sales invoice {{ itemData.number }}</div>
        <i v-if="itemData.changed_after_export" style="font-size: 1.2rem" class="pi pi-exclamation-triangle warning-color p-ml-1" v-tooltip.top="'Changed after export'"></i>
      </div>
      <div class="p-dialog-title" v-else>New sales invoice</div>
    </template>
    <MessageBar v-show="exportInvoiceToSmartAccounts && !dataIsSending && (invoiceFinancialPeriodIsClosed || selectedIssuedDateFinancialPeriodIsClosed)"
                :text="'Warning! Financial period already closed.'"
                :fixedToTop="false"
                :severity="'warn'"/>

    <Spinner v-if="dataIsSending"></Spinner>
    <div v-else class="p-formgrid p-grid p-pt-3">
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-4 p-mb-5">
        <div class="p-float-label dropdown-wrapper">
          <CustomDropdown id="sales-invoice-customer"
                    :disabled="!!customerDisabled"
                    class="dropdown__select"
                    :class="{
                      'p-invalid' : submitted && (isAnyActiveServicePosition && !selectedCustomer),
                      'dropdown__select--border-radius': customerDisabled,
                      'dropdown__select--one-button-width': userCanEditCustomer && !customerDisabled,
                      'dropdown__select--two-buttons-width': userCanEditCustomer && !customerDisabled}"
                    :searchData="customerSearchData"
                    v-model="selectedCustomer"
                    :options="customers"
                    filterPlaceholder="Search customer"
                    :emptyMessage="'Please enter 3 or more characters'"
                    :emptyFilterMessage="showCustomerMinCharsMessage ? 'Please enter 3 or more characters' : 'No results found'"
                    :filterFields="['first_name', 'last_name', 'full_phone_number', 'email', 'company_name', 'reg_number', 'company_full_phone_number', 'company_email']"
                    :filter="true"
                    :createButton="true"
                    :clearSearchData="!visible"
                    :loading="customersDataIsLoading"
                    @change="changeCustomer"
                    @filter="searchCustomers"
                    @create-new="createCustomer"
                    :showClear="!customerDisabled">
            <template #value="slotProps">
              <div v-if="slotProps.value" class="p-d-flex p-align-center">
<!--                <i class="ti-user p-mr-2"></i>-->
                <CustomerStatusHistoryButton :customerStatus="slotProps.value.customerStatus" :isChangeable="false"/>
                <span v-if="slotProps.value.type === constants.userTypes.legal_entity && slotProps.value.company_name">{{ slotProps.value.company_name }} ({{ slotProps.value.first_name }} {{ slotProps.value.last_name }})</span>
                <span v-else>{{ slotProps.value.first_name }} {{ slotProps.value.last_name }}</span>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div v-if="slotProps.option" class="p-d-flex p-ai-center p-jc-between">
                <div class="p-d-flex p-align-center">
<!--                  <i class="ti-user p-mr-2"></i>-->
                  <CustomerStatusHistoryButton :customerStatus="slotProps.option.customerStatus" :isChangeable="false"/>
                  <span v-if="slotProps.option.type === constants.userTypes.legal_entity && slotProps.option.company_name">{{ slotProps.option.company_name }} ({{ slotProps.option.first_name }} {{ slotProps.option.last_name }})</span>
                  <span v-else>{{ slotProps.option.first_name }} {{ slotProps.option.last_name }}</span>
                </div>
                <div class="p-ml-1" v-if="slotProps.option.type === constants.userTypes.legal_entity && slotProps.option.company_name"><span v-if="slotProps.option.company_phone_code">+{{ slotProps.option.company_phone_code }}</span> {{ slotProps.option.company_phone_number }}</div>
                <div class="p-ml-1" v-else><span v-if="slotProps.option.phone_code">+{{ slotProps.option.phone_code }}</span> {{ slotProps.option.phone_number }}</div>
              </div>
            </template>
          </CustomDropdown>
          <div class="dropdown__buttons" v-show="!customerDisabled">
            <Button v-if="userCanEditCustomer" @click="editCustomer" class="dropdown__edit-button p-d-flex p-ai-center p-jc-center"><i class="ti-pencil"></i></Button>
            <Button @click="createCustomer" class="dropdown__create-button p-d-flex p-ai-center p-jc-center"><i class="ti-plus"></i></Button>
          </div>
          <label for="sales-invoice-customer">Customer<span v-show="isAnyActiveServicePosition" class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && (isAnyActiveServicePosition && !selectedCustomer)">{{ $t('Required field') }}</small>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-4 p-mb-5">
        <div class="p-float-label dropdown-wrapper">
          <CustomDropdown id="sales-invoice-car"
                    :disabled="!!carDisabled"
                    class="dropdown__select"
                    :class="{'dropdown__select--border-radius': carDisabled || (!selectedCar && !selectedCustomer), 'dropdown__select--one-button-width': ((selectedCustomer && !selectedCar) || (!selectedCustomer && selectedCar)) && !carDisabled, 'dropdown__select--two-buttons-width': selectedCar && selectedCustomer && !carDisabled}"
                    :searchData="carSearchData"
                    v-model="selectedCar"
                    :options="cars"
                    filterPlaceholder="Search car"
                    :emptyMessage="'Please enter 3 or more characters'"
                    :emptyFilterMessage="showCarMinCharsMessage ? 'Please enter 3 or more characters' : 'No results found'"
                    :filterFields="['plate_number', 'vin', 'model']"
                    :filter="true"
                    :createButton="!!selectedCustomer"
                    :clearSearchData="!visible"
                    :loading="carsDataIsLoading"
                    @change="changeCar"
                    @filter="searchCars"
                    @create-new="createCar"
                    :showClear="!carDisabled">
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <div class="p-d-flex p-ai-center">
                  <div>
                    <i class="ti-car p-mr-2"></i>
                    <span v-if="slotProps.value.make">{{ slotProps.value.make.name }}</span> <span v-if="slotProps.value.model">{{ slotProps.value.model.name }}</span><span v-if="slotProps.value.plate_number">, {{ slotProps.value.plate_number }}</span>
                  </div>
                </div>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div v-if="slotProps.option" class="p-d-flex p-ai-center p-jc-between">
                <div>
                  <i class="ti-car p-mr-2"></i>
                  <span v-if="slotProps.option.make">{{ slotProps.option.make.name }}</span> <span v-if="slotProps.option.model">{{ slotProps.option.model.name }}</span><span v-if="slotProps.option.year">, {{ slotProps.option.year }} </span><span v-if="slotProps.option.vin">, {{ slotProps.option.vin }}</span>
                </div>
                <div class="p-ml-1" v-if="slotProps.option.plate_number">{{ slotProps.option.plate_number }}</div>
              </div>
            </template>
          </CustomDropdown>
          <div class="dropdown__buttons" v-show="!carDisabled">
            <Button v-if="selectedCar" @click="editCar" class="dropdown__edit-button p-d-flex p-ai-center p-jc-center" :class="{'dropdown__edit-button--rounded': selectedCar && !selectedCustomer}"><i class="ti-pencil"></i></Button>
            <Button v-if="selectedCustomer" @click="createCar" class="dropdown__create-button p-d-flex p-ai-center p-jc-center"><i class="ti-plus"></i></Button>
          </div>
          <label for="sales-invoice-car">Car <span v-if="customerCars?.length">({{ customerCars.length }})</span></label>
        </div>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-4 p-mb-5">
        <div class="p-float-label">
          <CustomDropdown id="sales_invoice_state"
                    disabled
                    :class="{'p-invalid' : submitted && !selectedState}"
                    v-model="selectedState"
                    :options="SalesInvoiceStates"
                    optionLabel="label"
                    :filter="false"
                    :showClear="false">
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <span class="state-badge" :class="slotProps.value.color">{{ slotProps.value.label[$i18n.locale] }}</span>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div>
                <span class="state-badge" :class="slotProps.option.color">{{ slotProps.option.label[$i18n.locale] }}</span>
              </div>
            </template>
          </CustomDropdown>
          <label for="sales_invoice_state">State<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !selectedState">{{ $t('Required field') }}</small>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-4 p-mb-5">
        <div class="p-float-label">
<!--          @input="changeIssuedDate"-->
<!--          @blur="changeIssuedDate"-->
          <Calendar
              id="invoice_issued"
              v-model="issued"
              :disabled="issuedDateIsDisabled || !userIsAdminOrAccountant"
              @date-select="changeIssuedDate"
              @blur="changeIssuedDate"
              @show="toogleDropdownOverlayVisibility(true)"
              @hide="toogleDropdownOverlayVisibility(false)"
              :class="{'p-invalid' : (issued && !issuedDateIsValid) || submitted && !issued || (issued && payment_due && payment_due < issued)}"
              :dateFormat="settings.dateFormat"
              :showIcon="true"
              :monthNavigator="true"
              :yearNavigator="true"
              :showButtonBar="true"
              :minDate="computedMinDateValue"
              :selectOtherMonths="true"
              autocomplete="off"/>
          <label for="invoice_issued">Issued<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !issued">{{ $t('Required field') }}</small>
        <small class="p-invalid" v-if="issued && !issuedDateIsValid">{{ $t('Invalid date format') }}</small>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-4 p-mb-5">
        <div class="p-float-label">
<!--          @input="checkPaymentDueDateIsValid"-->
<!--          @blur="checkPaymentDueDateIsValid"-->
          <Calendar
              id="payment_due"
              @date-select="changePaymentDueDate"
              @date-blur="changePaymentDueDate"
              @show="toogleDropdownOverlayVisibility(true)"
              @hide="toogleDropdownOverlayVisibility(false)"
              v-model="payment_due"
              :disabled="paymentDueDateIsDisabled"
              :class="{'p-invalid' : (payment_due && !paymentDueDateIsValid) || submitted && !payment_due || (issued && payment_due && payment_due < issued)}"
              :dateFormat="settings.dateFormat"
              :showIcon="true"
              :monthNavigator="true"
              :yearNavigator="true"
              :showButtonBar="true"
              :yearRange="(new Date().getFullYear() - 5) + ':' + (new Date().getFullYear() + 1)"
              :selectOtherMonths="true"
              autocomplete="off"/>
          <label for="payment_due">Payment due<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !payment_due">{{ $t('Required field') }}</small>
        <small class="p-invalid" v-if="payment_due && !paymentDueDateIsValid">{{ $t('Invalid date format') }}</small>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-4 p-mb-5">
        <div class="p-float-label">
          <InputNumber id="penalty"
                       :locale="computedNumberInputLocale"
                       v-model="itemData.penalty"
                       :disabled="penaltyInputIsDisabled"
                       mode="decimal"
                       suffix="%"
                       :min="0"
                       :max="20"
                       :minFractionDigits="2"
                       :maxFractionDigits="2"
                       autocomplete="off"/>
          <label for="penalty">Penalty</label>
        </div>
      </div>

<!--      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 add-position-buttons" :class="{'p-mb-5': noPositions && !computedDeletedPositionsExists}">-->
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 add-position-buttons">
<!--        <Button :disabled="positionsEditingIsDisabled" @click="addNewPosition(1)" v-tooltip.top="'Add service'" class="p-button-outlined add-position-button">-->
<!--          <i class="ti-plus p-mr-1"></i>-->
<!--          <i class="ti-hummer"></i>-->
<!--        </Button>-->
<!--        <Button :disabled="positionsEditingIsDisabled" @click="addNewPosition(2)" v-tooltip.top="'Add request'" class="p-button-outlined add-position-button">-->
<!--          <i class="ti-plus p-mr-1"></i>-->
<!--          <i class="ti-truck"></i>-->
<!--        </Button>-->
<!--        <Button :disabled="positionsEditingIsDisabled" @click="addNewPosition(3)" v-tooltip.top="'Add warehouse item'"  class="p-button-outlined add-position-button">-->
<!--          <i class="ti-plus p-mr-1"></i>-->
<!--          <i class="ti-shopping-cart"></i>-->
<!--        </Button>-->
<!--        <Button :disabled="positionsEditingIsDisabled" @click="addNewPosition(4)" v-tooltip.top="'Add free position'" class="p-button-outlined add-position-button p-mr-5">-->
<!--          <i class="ti-plus p-mr-1"></i>-->
<!--          <i class="ti-pencil"></i>-->
<!--        </Button>-->

<!--        <div class="p-d-flex p-ai-center p-mt-3 p-mb-3 p-mr-3">-->
<!--          <div>-->
<!--            <InputSwitch :disabled="item.order_id" id="use_consumable_modal" v-model="useConsumables" @change="toggleConsumable"/>-->
<!--          </div>-->
<!--          <label for="use_consumable_modal" class="switch-label" :class="{'pointer' : !positionsEditingIsDisabled}">Use consumables?</label>-->
<!--        </div>-->
        <div v-if="!positionsEditingIsDisabled" class="p-d-flex p-ai-center p-mt-3 p-mb-3 p-mr-3">
          <div class="p-d-flex p-ai-center">
            <InputSwitch :disabled="positionsEditingIsDisabled" id="use_consumables_modal" v-model="useConsumables" @change="toggleConsumable"/>
          </div>
          <label for="use_consumables_modal" class="switch-label" :class="{'pointer':!positionsEditingIsDisabled}">Use consumables?</label>
        </div>
<!--        <div class="p-d-flex p-ai-center p-mt-3 p-mb-3 p-mr-3" v-if="computedDeletedPositionsExists">-->
<!--          <div>-->
<!--            <InputSwitch id="show_deleted_modal" v-model="showDeleted"/>-->
<!--          </div>-->
<!--          <label for="show_deleted_modal" class="switch-label pointer">Show Deleted</label>-->
<!--        </div>-->
        <div class="p-d-flex p-ai-center p-mt-3 p-mb-3" :class="{'p-mr-3' : settings.useSmartAccounts}">
          <div>
            <div class="p-d-flex p-ai-center">
              <InputSwitch id="show_car_data_modal" v-model="showCarData" :disabled="editingIsDisabled"/>
            </div>
          </div>
          <label for="show_car_data_modal" class="switch-label pointer" :class="{'no-pointer':editingIsDisabled}">Show car data on invoice</label>
        </div>
        <div class="p-d-flex p-ai-center p-mt-3 p-mb-3" v-if="settings.useSmartAccounts">
          <div class="p-d-flex p-ai-center">
            <InputSwitch id="export_to_smart_accounts_switch"
                         :disabled="exportInvoiceTogglerIsDisabled || editingIsDisabled"
                         v-model="exportInvoiceToSmartAccounts"/>
          </div>
          <label for="export_to_smart_accounts_switch" class="switch-label pointer" :class="{'no-pointer':exportInvoiceTogglerIsDisabled || editingIsDisabled}">Export to SmartAccounts</label>
        </div>
      </div>
<!--    </div>-->

      <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 table-wrapper" :class="{'p-mb-5' : showPositionsTable || computedDeletedPositionsExists }">
  <!--    <div class="table-wrapper">-->
        <div v-if="computedDeletedPositionsExists">
          <a @click.prevent="showDeleted = !showDeleted" class="p-link">
            <i style="font-size: 0.9em" class="pi p-mr-1" :class="showDeleted ? 'pi-minus' :'pi-plus'"></i>
            <span>{{ showDeleted ? 'Hide deleted' : 'Show deleted' }}</span>
          </a>
        </div>
        <table v-if="showPositionsTable" class="p-datatable p-datatable-striped p-datatable-sm p-datatable-hoverable-rows positions-table positions-table">
          <thead class="p-datatable-thead">
            <tr>
              <th class="table-title__icon"></th>
              <th class="table-title__name--first" v-if="settings.useSmartAccounts && exportInvoiceToSmartAccounts">SA Code</th>
  <!--            <th class="table-title__name&#45;&#45;first"></th>-->
              <th class="table-title__name--second">Code</th>
              <th class="table-title__name--last">Name</th>
              <th class="table-title__qty">Qty</th>
              <th class="table-title__purchase">Buy</th>
              <th class="table-title__sell">Sell</th>
              <th class="table-title__discount" v-show="showDiscountColumn">Dis.(%)</th>
              <th class="table-title__sum">Sum</th>
              <th class="table-title__tax">Tax</th>
              <th class="table-title__total">Total</th>
              <th class="table-title__request-state"></th>
              <th class="table-title__info" v-if="itemData?.id"></th>
              <th class="table-title__info" v-if="havePositionsWithFixedQty"></th>
              <!--            <th class="table-title__invoice"></th>-->
  <!--            <th class="table-title__delete-btn"></th>-->
            </tr>
          </thead>
          <tbody class="p-datatable-tbody">
            <tr v-for="(position, index) of positions" :key="index">
              <template v-if="position.status === 1 || showDeleted">
                <td :class="{ 'new-item' : position.isNew, 'deleted-position-td':  position.status === 0 }">
<!--                  <i :class="{'ti-hummer':position.type === 1, 'ti-truck':position.type === 2, 'ti-shopping-cart':position.type === 3, 'ti-pencil':position.type === 4}"></i>-->
                  <div class="p-d-flex p-ai-center">
<!--                    <div class="p-mr-2" v-if="position.sorting">{{ position.sorting }}</div>-->
                    <i :class="{'ti-hummer':position.type === 1, 'ti-truck':position.type === 2, 'ti-shopping-cart':position.type === 3, 'ti-pencil':position.type === 4}"></i>
                  </div>
                </td>
                <td v-if="settings.useSmartAccounts && exportInvoiceToSmartAccounts" :class="{'new-item' : position.isNew, 'deleted-position-td':  position.status === 0}">
                  <CustomDropdown class="table-body__name-input--accounting-code"
                                  :class="{'p-invalid warning-background' : submitted && exportInvoiceToSmartAccounts && !position.selectedSmartAccountsCode }"
                                  :disabled="position.status === 0 || exportInvoiceCodeInputIsDisabled"
                                  v-model="position.selectedSmartAccountsCode"
                                  :options="smartAccountsItems"
                                  @change="changeSmartAccountsItem(position)"
                                  placeholder="Select item"
                                  :filter="true"
                                  :filterFields="['code', 'description']"
                                  :showClear="false">
                    <template #value="slotProps">
                      <div v-if="slotProps.value">
                        <span v-if="slotProps.value.code">{{ slotProps.value.code }} - </span>{{ slotProps.value.description }}
                      </div>
                      <span v-else>Select item</span>
                    </template>
                    <template #option="slotProps">
                      <div><span v-if="slotProps.option.code">{{ slotProps.option.code }} - </span>{{ slotProps.option.description }}</div>
                    </template>
                  </CustomDropdown>
                </td>

                <template v-if="position.type === 1">
  <!--                <td :class="{ 'new-item' : position.isNew, 'deleted-position-td':  position.status === 0 }">-->
  <!--                  <CustomDropdown :class="{'p-invalid warning-background' : submitted && !position.selectedService}"-->
  <!--                                      :disabled="position.status === 0 || positionsEditingIsDisabled"-->
  <!--                                      class="table-body__name-input&#45;&#45;first"-->
  <!--                                      v-model="position.selectedService"-->
  <!--                                      @change="changeService(position)"-->
  <!--                                      :options="servicesData"-->
  <!--                                      :filter="true"-->
  <!--                                      optionLabel="name"-->
  <!--                                      :showClear="false">-->
  <!--                    <template #value="slotProps">-->
  <!--                      <div v-if="slotProps.value">-->
  <!--                        <span>{{ slotProps.value.name }}</span>-->
  <!--                      </div>-->
  <!--                      <span v-else>Select service</span>-->
  <!--                    </template>-->
  <!--                    <template #option="slotProps">-->
  <!--                      <div>-->
  <!--                        <span>{{ slotProps.option.name }}</span>-->
  <!--                      </div>-->
  <!--                    </template>-->
  <!--                  </CustomDropdown>-->
  <!--                </td>-->
                  <td :class="{ 'new-item' : position.isNew, 'deleted-position-td':  position.status === 0 }">
                    <InputText class="table-body__name-input--code" v-if="position.selectedService" disabled v-model="position.selectedService.code"/>
  <!--                  {{ position }}-->
    <!--                <InputText disabled class="table-body__name-input&#45;&#45;first"></InputText>-->
    <!--                <CustomDropdown :disabled="position.status === 0 || !selectedMasters?.length"-->
    <!--                                    class="table-body__name-input&#45;&#45;second"-->
    <!--                                    v-model="position.selectedMaster"-->
    <!--                                    @change="changeMaster(position)"-->
    <!--                                    placeholder="Select master"-->
    <!--                                    :options="selectedMasters"-->
    <!--                                    :filter="false"-->
    <!--                                    optionLabel="full_name"-->
    <!--                                    :showClear="true">-->
    <!--                  <template #value="slotProps">-->
    <!--                    <div v-if="slotProps.value">-->
    <!--                      <span>{{ slotProps.value.full_name }}</span>-->
    <!--                    </div>-->
    <!--                    <span v-else>Select master</span>-->
    <!--                  </template>-->
    <!--                  <template #option="slotProps">-->
    <!--                    <div>-->
    <!--                      <span>{{ slotProps.option.full_name }}</span>-->
    <!--                    </div>-->
    <!--                  </template>-->
    <!--                </CustomDropdown>-->
                  </td>
<!--                  <td :class="{ 'new-item' : position.isNew, 'deleted-position-td':  position.status === 0 }">-->
<!--                    <InputText :disabled="!position.selectedService || position.status === 0 || positionsEditingIsDisabled"-->
<!--                               v-model.trim="position.name"-->
<!--                               :placeholder="$t('Name')"-->
<!--                               @input="updatePosition(position, false, 'name')"-->
<!--                               :class="{'p-invalid' : submitted && position.selectedService && !position.name}"-->
<!--                               class="table-body__name-input&#45;&#45;name"-->
<!--                               autocomplete="off"/>-->
<!--                  </td>-->
                </template>
                <template v-else-if="position.type === 2">
                  <td :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0, 'updated-item': position.reduced }">
                    <div v-tooltip.top="position.selectedSupplier?.company_name">
                      <InputText :placeholder="position.status === 0 || positionsEditingIsDisabled ? null : $t('Code')" :disabled="position.status === 0 || positionsEditingIsDisabled" v-model.trim="position.code" @input="updatePosition(position, $event.value, 'code')" class="table-body__name-input--code" autocomplete="off"/>
                    </div>
                  </td>
<!--                  <td :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0, 'updated-item': position.reduced }">-->
<!--                    <InputText :placeholder="$t('Name')"-->
<!--                               :disabled="position.status === 0 || positionsEditingIsDisabled"-->
<!--                               :class="{'p-invalid' : submitted && !position.name}"-->
<!--                               v-model.trim="position.name"-->
<!--                               @input="updatePosition(position, false, 'name')"-->
<!--                               class="table-body__name-input&#45;&#45;name"-->
<!--                               autocomplete="off"/>-->
<!--                  </td>-->
                </template>

                <template v-if="position.type === 3">
                  <td :class="{ 'new-item' : position.isNew, 'deleted-position-td':  position.status === 0 }">
                    <InputText :placeholder="$t('Code')" disabled v-model.trim="position.code" @input="updatePosition(position, $event.value, 'code')" class="table-body__name-input--code" autocomplete="off"/>
                  </td>
<!--                  <td :class="{ 'new-item' : position.isNew, 'deleted-position-td':  position.status === 0 }">-->
<!--                    <InputText :disabled="positionsEditingIsDisabled || !position.selectedWarehouseItem || position.status === 0"-->
<!--                               v-model.trim="position.name"-->
<!--                               :placeholder="$t('Name')"-->
<!--                               @input="updatePosition(position, false, 'name')"-->
<!--                               :class="{'p-invalid' : submitted && position.selectedWarehouseItem && !position.name}"-->
<!--                               class="table-body__name-input&#45;&#45;name"-->
<!--                               autocomplete="off"/>-->
<!--                  </td>-->
                </template>

                <template v-if="position.type === 4">
  <!--                <td :class="{ 'new-item' : position.isNew, 'deleted-position-td':  position.status === 0 }">-->
  <!--                  <InputText disabled class="table-body__name-input&#45;&#45;first"></InputText>-->
  <!--                </td>-->
                  <td :class="{ 'new-item' : position.isNew, 'deleted-position-td':  position.status === 0 }">
                    <InputText :placeholder="position.status === 0 || positionsEditingIsDisabled ? null : $t('Code')" :disabled="position.status === 0 || positionsEditingIsDisabled" v-model.trim="position.code" @input="updatePosition(position, $event.value, 'code')" class="table-body__name-input--code" autocomplete="off"/>
                  </td>
<!--                  <td :class="{ 'new-item' : position.isNew, 'deleted-position-td':  position.status === 0 }">-->
<!--                    <InputText :placeholder="$t('Name')"-->
<!--                               :disabled="position.status === 0 || positionsEditingIsDisabled"-->
<!--                               v-model.trim="position.name"-->
<!--                               @input="updatePosition(position, false, 'name')"-->
<!--                               :class="{'p-invalid' : submitted && !position.name}"-->
<!--                               class="table-body__name-input&#45;&#45;name"-->
<!--                               autocomplete="off"/>-->
<!--                  </td>-->
                </template>
                <td :class="{ 'new-item' : position.isNew, 'deleted-position-td':  position.status === 0, 'inactive-position-td': position.status === 2, 'updated-item': position.reduced }">
                  <InputText :placeholder="$t('Name')"
                             :disabled="(position.type === 1 && !position.selectedService) ||
                                        (position.type === 3 && !position.selectedWarehouseItem) ||
                                        position.status === 0 ||
                                        editingIsDisabled ||
                                        positionsEditingIsDisabled"
                             v-model.trim="position.name"
                             @input="updatePosition(position, false, 'name')"
                             :class="{'p-invalid warning-background' : submitted && !position.name}"
                             class="table-body__name-input--name"
                             autocomplete="off"/>
                </td>
                <td :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0 }">
                  <InputNumber v-if="position.type === 2"
                               :locale="computedNumberInputLocale"
                               :disabled="position.status === 0 || (!position.isNew && (position.state !== 1 && position.state !== 3)) || positionsEditingIsDisabled || position.fixed_qty"
                               @input="updatePosition(position, $event.value, 'qty', 99999, 0)"
                               v-model="position.qty"
                               :inputClass="{'p-invalid warning-background' : submitted && !position.qty && position.status !== 0}"
                               class="table-body__qty-input"
                               :useGrouping="false"
                               :min="0"
                               :max="99999"
                               :showButtons="!positionsEditingIsDisabled && (position.isNew && position.status !== 0 || (!position.isNew && position.state <= 3 && position.status !== 0))"
                               autocomplete="off"/>
                  <InputNumber v-else
                               :locale="computedNumberInputLocale"
                               :disabled="position.status === 0 || positionsEditingIsDisabled"
                               @input="updatePosition(position, $event.value, 'qty', 99999, -99999)"
                               v-model="position.qty"
                               :inputClass="{'p-invalid warning-background' : submitted && !position.qty}"
                               class="table-body__qty-input"
                               mode="decimal"
                               :min="-99999"
                               :max="99999"
                               :showButtons="!positionsEditingIsDisabled && position.status !== 0"
                               :minFractionDigits="position.type === 3 && !position.is_decimal ? 0 : 2"
                               :maxFractionDigits="position.type === 3 && !position.is_decimal ? 0 : 2"
                               autocomplete="off"/>
                </td>
                <td :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0, 'updated-item': position.reduced }">
                  <InputNumber @input="updatePosition(position, $event.value, 'purchase_price', 1000000, 0)"
                               :locale="computedNumberInputLocale"
                               :disabled="positionsEditingIsDisabled || (position.type === 1 || position.type === 3 || position.status === 0) || (position.type === 2 && +position.fixed_qty > 0)"
                               v-model="position.purchase_price" class="table-body__purchase-input"
                               :inputClass="{'p-invalid warning-background' : position.sell_price && +position.sell_price && position.sell_price < position.purchase_price}"
                               mode="decimal"
                               :min="0"
                               :max="1000000"
                               :minFractionDigits="2"
                               :maxFractionDigits="4"
                               autocomplete="off"/>
                </td>
                <td :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0, 'updated-item': position.reduced }">
                  <InputNumber @input="updatePosition(position, $event.value, 'sell_price', 1000000, 0)"
                               :locale="computedNumberInputLocale"
                               :disabled="positionsEditingIsDisabled || (position.type === 3 && !position.selectedWarehouseItem)|| position.type === 1 || position.status === 0"
                               v-model="position.sell_price"
                               :inputClass="{'p-invalid warning-background' : (submitted && !position.sell_price) || (position.sell_price && +position.sell_price && position.sell_price < position.purchase_price)}"
                               class="table-body__sell-input"
                               mode="decimal"
                               :min="0"
                               :max="1000000"
                               :minFractionDigits="2"
                               :maxFractionDigits="4"
                               autocomplete="off"/>
                </td>
                <td v-show="showDiscountColumn" :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0, 'updated-item': position.reduced }">
                  <InputNumber :locale="computedNumberInputLocale"
                               @input="updatePosition(position, $event.value, 'discount', 100, 0)"
                               :disabled="positionsEditingIsDisabled || position.status === 0"
                               v-model="position.discount"
                               class="table-body__discount-input"
                               mode="decimal"
                               :min="0"
                               :max="100"
                               :minFractionDigits="2"
                               :maxFractionDigits="2"
                               autocomplete="off"/>
                </td>
                <td :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0, 'updated-item': position.reduced }">
                  <InputNumber :locale="computedNumberInputLocale" v-model="position.sum" mode="decimal" class="table-body__sum-input" disabled :minFractionDigits="2" :maxFractionDigits="4"/>
                </td>
                <td :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0, 'updated-item': position.reduced }">
                  <CustomDropdown @change="changeTax(position, $event.value)"
                                      class="table-body__tax-input"
                                      :class="{'p-invalid warning-background' : submitted && !position.selectedTax}"
                                      :disabled="position.status === 0 || positionsEditingIsDisabled"
                                      v-model="position.selectedTax"
                                      :options="taxesData"
                                      :filter="false"
                                      optionLabel="value"
                                      :showClear="false">
                    <template #value="slotProps">
                      <div v-if="slotProps.value">
                        <span>{{ slotProps.value.value }}%</span>
                      </div>
                      <span v-else style="visibility: hidden">.</span>
                    </template>
                    <template #option="slotProps">
                      <div>
                        <span>{{ slotProps.option.value }}% ({{ slotProps.option.name }})</span>
                      </div>
                    </template>
                  </CustomDropdown>
                </td>
                <td :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0, 'updated-item': position.reduced }">
                  <InputNumber :locale="computedNumberInputLocale" v-model="position.total" class="table-body__total-input" mode="decimal" disabled :minFractionDigits="2" :maxFractionDigits="4"/>
                </td>
                <td :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0, 'updated-item': position.reduced }">
                  <RequestPositionStateButton :isDisabled="positionsEditingIsDisabled" :position="position" :isModal="true"/>
                </td>
                <td class="p-p-1" v-if="itemData?.id" :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0, 'updated-item': position.reduced }">
                  <PositionHistoryButton :position="position"/>
                </td>
                <td class="p-p-1" v-if="havePositionsWithFixedQty" :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0, 'updated-item': position.reduced }">
                  <PositionFixedQtyButton :position="position"/>
                </td>
  <!--              <td :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0, 'updated-item': position.reduced }">-->
  <!--                <DeleteButton v-if="!positionsEditingIsDisabled && position.status !== 0 && !position.isNew && (position.type === 2 && (position.state <= 3) || position.type !== 2)" @click="confirmPositionDelete(position)"/>-->
  <!--                <ReduceButton v-else-if="!positionsEditingIsDisabled && position.status !== 0 && !position.isNew && (position.type === 2 && (position.state > 3))" @click="reduceRequestPositionOnClick(position)"/>-->
  <!--                <RemoveButton v-else-if="!positionsEditingIsDisabled && position.status !== 0 && position.isNew" @click="removePosition(index)"/>-->
  <!--              </td>-->
              </template>
            </tr>

          <tr v-if="useConsumables && consumable">
            <td>
              <i class="ti-spray"></i>
            </td>

            <td v-if="settings.useSmartAccounts && exportInvoiceToSmartAccounts">
              <CustomDropdown :class="{'p-invalid warning-background' : submitted && exportInvoiceToSmartAccounts && !consumable.selectedSmartAccountsCode}"
                              class="table-body__name-input--accounting-code"
                              :disabled="exportInvoiceCodeInputIsDisabled"
                              v-model="consumable.selectedSmartAccountsCode"
                              :options="smartAccountsItems"
                              @change="changeSmartAccountsItem(consumable)"
                              placeholder="Select item"
                              :filter="true"
                              :filterFields="['code', 'description']"
                              :showClear="false">
                <template #value="slotProps">
                  <div v-if="slotProps.value">
                    <span v-if="slotProps.value.code">{{ slotProps.value.code }} - </span>{{ slotProps.value.description }}
                  </div>
                  <span v-else>Select item</span>
                </template>
                <template #option="slotProps">
                  <div><span v-if="slotProps.option.code">{{ slotProps.option.code }} - </span>{{ slotProps.option.description }}</div>
                </template>
              </CustomDropdown>
            </td>

            <td></td>
            <td>
              <InputText :class="{'p-invalid warning-background' : submitted && useConsumables && consumable && !consumable.name}"
                         :disabled="positionsEditingIsDisabled"
                         v-model.trim="consumable.name"
                         class="table-body__name-input--name"
                         autocomplete="off"/>
            </td>
            <td>
              <InputNumber :locale="computedNumberInputLocale" v-model="consumable.qty" class="table-body__qty-input" mode="decimal" disabled :min="2" :max="999" :minFractionDigits="2" :maxFractionDigits="2" autocomplete="off"/>
            </td>
            <td>
<!--              <InputNumber :value="consumable.purchase_price" -->
<!--              :locale="computedNumberInputLocale"-->
<!--                           class="table-body__purchase-input" -->
<!--                           mode="decimal" -->
<!--                           disabled -->
<!--                           :min="0" -->
<!--                           :minFractionDigits="2" -->
<!--                           :maxFractionDigits="4" -->
<!--                           autocomplete="off"/>-->
            </td>
            <td>
              <InputNumber v-model="consumable.sell_price"
                           :locale="computedNumberInputLocale"
                           disabled
                           class="table-body__sell-input"
                           mode="decimal"
                           :min="0"
                           :minFractionDigits="2"
                           :maxFractionDigits="4"
                           autocomplete="off"/>
            </td>
            <td v-show="showDiscountColumn">
              <InputNumber :locale="computedNumberInputLocale"
                           :disabled="positionsEditingIsDisabled"
                           @input="updatePosition(consumable, $event.value, 'discount', 100, 0)"
                           v-model="consumable.discount"
                           class="table-body__discount-input"
                           mode="decimal"
                           :min="0"
                           :max="100"
                           :minFractionDigits="2"
                           :maxFractionDigits="2"
                           autocomplete="off"/>
            </td>
            <td>
              <InputNumber :locale="computedNumberInputLocale" v-model="consumable.sum" class="table-body__sum-input" mode="decimal" disabled :minFractionDigits="2" :maxFractionDigits="4"/>
            </td>
            <td>
              <CustomDropdown @change="changeTax(consumable, $event.value)"
                                  :disabled="positionsEditingIsDisabled"
                                  class="table-body__tax-input"
                                  :class="{'p-invalid' : submitted && useConsumables && consumable && !consumable.selectedTax}"
                                  v-model="consumable.selectedTax"
                                  :options="taxesData"
                                  :filter="false"
                                  optionLabel="value"
                                  :showClear="false">
                <template #value="slotProps">
                  <div v-if="slotProps.value">
                    <span>{{ slotProps.value.value }}%</span>
                  </div>
                  <span v-else style="visibility: hidden">.</span>
                </template>
                <template #option="slotProps">
                  <div>
                    <span>{{ slotProps.option.value }}% ({{ slotProps.option.name }})</span>
                  </div>
                </template>
              </CustomDropdown>
            </td>
            <td>
              <InputNumber :locale="computedNumberInputLocale" v-model="consumable.total" class="table-body__total-input" mode="decimal" disabled :minFractionDigits="2" :maxFractionDigits="4"/>
            </td>
            <td></td>
            <td v-if="itemData?.id"></td>
            <!--            <td></td>-->
<!--            <td></td>-->
            <td v-if="havePositionsWithFixedQty"></td>
          </tr>

          <tr style="background-color: inherit!important;">
            <td style="padding-right: 10px; line-height: 1.5; text-align: right" colspan="15">
              <div class="p-text-right p-d-flex p-flex-column p-align-end">
                <div class="p-d-flex p-ai-center">
                  <div>Sum without tax: </div>
                  <div class="table-summary__price">{{ formatCurrency(summary.sum) }}</div>
                </div>
                <div class="p-d-flex p-ai-center">
                  <div>Tax: </div>
                  <div class="table-summary__price">{{ formatCurrency(summary.tax) }}</div>
                </div>
                <div class="p-d-flex p-ai-center p-text-bold">
                  <div>Grand total: </div>
                  <div class="table-summary__price">{{ formatCurrency(summary.grand_total) }}</div>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-6 p-m-0">
        <div class="p-field p-mb-5">
<!--          <div class="p-float-label p-input-icon-right" >-->
<!--            <i class="ti-comment-alt"></i>-->
<!--            <Textarea id="comment_on_invoice"-->
<!--                      class="textarea-vertical-resize"-->
<!--                      v-model.trim="itemData.invoice_comment"-->
<!--                      :disabled="editingIsDisabled"-->
<!--                      rows="2"-->
<!--                      :autoResize="true"-->
<!--                      autocomplete="off"/>-->
<!--            <label for="comment_on_invoice">Comment on invoice</label>-->
<!--          </div>-->

          <LimitedCharsTextarea :submitted="submitted"
                                :rows="2"
                                :disabled="editingIsDisabled"
                                v-model="itemData.invoice_comment"
                                :maxChars="salesInvoiceCommentMaxChars"
                                :id="'comment-on-invoice'"
                                :label="'Comment on invoice'"/>
          <div class="p-mt-2 p-mb-3" v-if="itemData.comments_history?.find(comment => comment.type === 1)" >
            <span>{{ $t('Change history') }}: </span>
            <a @click.prevent="toggleCustomerCommentsHistoryOverlay($event, itemData.id)" href="#" class="p-link table-link-icon">
              <i class="ti-time"></i>
              <span style="font-size: 0.9em; position: relative; top: -1px;" class="p-ml-1">{{ itemData.comments_history.find(comment => comment.type === 1) }}</span>
            </a>
          </div>
        </div>
        <OverlayPanel v-if="itemData.id" :ref="'mch' + itemData.id">
          <div v-for="(history, index) of (itemData.comments_history).filter(comment => comment.type === 1).sort((a, b) => b.created_at - a.created_at)" :key="index" class="p-mb-2 p-mr-3">
            <div class="p-mb-2">
              <span v-if="history.created_at" >{{ formatDate(+history.created_at) }},</span> <span v-if="history.creator && history.creator.first_name">{{ history.creator.first_name }}</span> <span v-if="history.creator && history.creator.last_name">{{ history.creator.last_name }}</span>
            </div>
            <div style="font-size: 0.92em; max-width: 600px">{{ history.comment || '-' }}</div>
            <hr v-if="index !== itemData.comments_history.filter(comment => comment.type === 1)?.length - 1">
          </div>
        </OverlayPanel>

        <div class="p-field p-mb-5">
<!--          <div class="p-float-label p-input-icon-right" >-->
<!--            <i class="ti-comment-alt" />-->
<!--            <Textarea id="staff_comment"-->
<!--                      class="textarea-vertical-resize"-->
<!--                      v-model.trim="itemData.staff_comment"-->
<!--                      :disabled="editingIsDisabled"-->
<!--                      rows="2"-->
<!--                      :autoResize="true"-->
<!--                      autocomplete="off"/>-->
<!--            <label for="staff_comment">Internal information</label>-->
<!--          </div>-->
          <LimitedCharsTextarea :submitted="submitted"
                                :rows="2"
                                :disabled="editingIsDisabled"
                                v-model="itemData.staff_comment"
                                :maxChars="salesInvoiceStaffCommentMaxChars"
                                :id="'sales-invoice-staff-comment'"
                                :label="'Internal information'"/>
        </div>
        <div class="p-field p-mb-5">
<!--          <div class="p-float-label p-input-icon-right">-->
<!--            <i class="ti-comment-alt" />-->
<!--            <Textarea id="recommendation"-->
<!--                      v-model.trim="itemData.recommendation"-->
<!--                      :disabled="editingIsDisabled"-->
<!--                      rows="2"-->
<!--                      :autoResize="true"-->
<!--                      autocomplete="off"/>-->
<!--            <label for="recommendation">Recommendations</label>-->
<!--          </div>-->
          <LimitedCharsTextarea :submitted="submitted"
                                :rows="2"
                                :disabled="editingIsDisabled"
                                v-model="itemData.recommendation"
                                :maxChars="salesInvoiceRecommendationMaxChars"
                                :id="'sales-invoice-recommendation'"
                                :label="'Recommendations'"/>
<!--          <div class="p-mt-2 p-mb-3" v-if="itemData.recommendation_history?.length">-->
<!--            <span>{{ $t('Change history') }}: </span>-->
<!--            <a @click.prevent="toggleRecommendationHistoryOverlay($event, item.id)" href="#" class="p-link table-link-icon">-->
<!--              <i class="ti-time"></i>-->
<!--              <span style="font-size: 0.9em; position: relative; top: -1px;" class="p-ml-1">{{ itemData.recommendation_history.length }}</span>-->
<!--            </a>-->
<!--          </div>-->
<!--          <OverlayPanel v-if="itemData.id" :ref="'mrh' + itemData.id">-->
<!--            <div v-for="(history, index) of (itemData.recommendation_history).sort((a, b) => b.created_at - a.created_at)" :key="index" class="p-mb-2 p-mr-3">-->
<!--              <div class="p-mb-2">-->
<!--                <span v-if="history.created_at" >{{ formatDate(+history.created_at) }},</span> <span v-if="history.creator && history.creator.first_name">{{ history.creator.first_name }}</span> <span v-if="history.creator && history.creator.last_name">{{ history.creator.last_name }}</span>-->
<!--              </div>-->
<!--              <div style="font-size: 0.92em; max-width: 600px">{{ history.recommendation || '-' }}</div>-->
<!--              <hr v-if="index !== itemData.recommendation_history.length - 1">-->
<!--            </div>-->
<!--          </OverlayPanel>-->
        </div>
<!--        </div>-->
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-6 p-mb-5">
        <div class="payments-table-wrapper">
          <table class="p-datatable p-datatable-striped p-datatable-sm p-datatable-hoverable-rows payments-table">
            <thead class="p-datatable-thead">
              <tr>
                <th>Type</th>
                <th style="width: 120px">Sum</th>
                <th style="width: 5%">Date</th>
<!--                <th style="width: 30px" v-if="payments.filter(payment => payment.id)?.length"></th>-->
                <th style="width: 30px" v-if="payments?.find(payment => payment.id)"></th>
                <th style="width: 57px"></th>
              </tr>
            </thead>
            <tbody class="p-datatable-tbody">
              <tr v-for="(payment, index) of payments" :key="index" :class="{'new-item': payment.isNew}">
                <td>
<!--                  <InputText v-model="payment.type"></InputText>-->
                  <CustomDropdown v-model="payment.selectedType"
                            :disabled="!payment.isNew"
                            :class="{'p-invalid' : submitted && !payment.selectedType}"
                            :options="paymentTypes"
                            optionLabel="label"
                            :showClear="false">
                    <template #value="slotProps">
                      <div class="country-item country-item-value" v-if="slotProps.value">
                        <span>{{ slotProps.value.label[$i18n.locale] }}
                          <span v-if="slotProps.value.cashBoxName">({{ slotProps.value.cashBoxName }})</span>
                        </span>
                      </div>
                      <span v-else style="visibility: hidden">.</span>
                    </template>
                    <template #option="slotProps">
                      <div>
                        <span>{{ slotProps.option.label[$i18n.locale] }}
                          <span v-if="slotProps.option.cashBoxName">({{ slotProps.option.cashBoxName }})</span>
                        </span>
                      </div>
                    </template>
                  </CustomDropdown>
                </td>
                <td>
                  <InputNumber :disabled="!payment.isNew"
                               :locale="computedNumberInputLocale"
                                style="min-width: 80px;"
                                @input="changePaymentValue($event.value, payment)"
                                :inputClass="{'p-invalid' : submitted && !payment.value}"
                                v-model="payment.value"
                                :useGrouping="true"
                                mode="decimal"
                                :minFractionDigits="2"
                                :maxFractionDigits="2">
                  </InputNumber>
                </td>
                <td>
<!--                  @input="checkPaymentDateIsValid(payment)"-->
<!--                  @blur="checkPaymentDateIsValid(payment)"-->
                  <Calendar style="width: 165px;"
                            :locale="computedNumberInputLocale"
                            :disabled="!payment.isNew"
                            id="invoice_payment_date"
                            v-model="payment.date"
                            @date-select="checkPaymentDateIsValid(payment)"
                            @show="toogleDropdownOverlayVisibility(true)"
                            @hide="toogleDropdownOverlayVisibility(false)"
                            :class="{'p-invalid' : (payment.date && !payment.dateIsValid) || (submitted && !payment.date)}"
                            :dateFormat="settings.dateFormat"
                            :showIcon="true"
                            :monthNavigator="true"
                            :yearNavigator="true"
                            :showButtonBar="true"
                            :showTime="true"
                            :maxDate="new Date()"
                            :stepMinute="10"
                            :yearRange="(new Date().getFullYear() - 5) + ':' + (new Date().getFullYear() + 1)"
                            :selectOtherMonths="true"
                            autocomplete="off"/>
                  <small class="p-invalid" v-if="payment.date && !payment.dateIsValid">{{ $t('Invalid date format') }}</small>
<!--                  <div v-else>-->
<!--                    {{ formatDay(payment.date) }}-->
<!--                  </div>-->
                </td>
                <td v-if="payments.find(payment => payment.id)">
<!--                <td>-->
                  <span v-if="payment.id">
                    <a @click.prevent="togglePaymentHistoryOverlay($event, payment.id)" href="#" class="p-link table-link-icon">
                      <i class="ti-time"></i>
                    </a>
                    <OverlayPanel :ref="'mpaymenth' + payment.id">
                      <div>
                        <span v-if="payment.created_at"><b>{{ $t('Created') }}:</b> {{ formatDate(payment.created_at) }}</span><span v-if="payment.creator && payment.creator.first_name">, {{ payment.creator.first_name }}</span> <span v-if="payment.creator && payment.creator.last_name">{{ payment.creator.last_name }}</span>
                      </div>
                    </OverlayPanel>
                  </span>
                </td>
                <td>
                  <DeleteButton :disabled="disableDeletePaymentButtons" @click="onDeletePaymentClick(index, payment.id)"/>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="p-d-flex p-jc-between p-p-1 p-mt-2">
            <div class="p-pr-3">Unpaid: {{ formatCurrency(unpaidAmount) }} / {{ formatCurrency(summary.grand_total) }}</div>
            <Button v-if="!!+unpaidAmount" :disabled="disableAddPaymentBtn" @click="addNewPayment" class="p-button-text" icon="pi pi-plus" :label="$t('Add')"></Button>
          </div>
        </div>

        <div class="p-mt-5" v-if="itemData.file_path">
          <hr>
          <div class="p-d-flex p-jc-between p-ai-center p-flex-wrap">
<!--            <div>Sales invoice #: {{ itemData.number }}</div>-->
            <div class="p-d-flex">
<!--              <div>Sales invoice #: {{ itemData.number }}</div>-->
<!--              <div class="p-ml-3 p-d-flex p-ai-center" v-if="itemData.sent_at" v-tooltip.top="'Sent at: ' + formatDate(itemData.sent_at)">-->
<!--                <i style="font-size: 1.2em" class="ti-email"></i>-->
<!--              </div>-->
              <div v-if="itemData.sent_at">
                Sent at: {{ formatDate(itemData.sent_at) }}
              </div>
            </div>
            <div>
              <GetSalesInvoicePdfButton :filePath="itemData.file_path"/>
            </div>
            <Button :disabled="!selectedCustomerHasEmailAddress" v-if="userEmailAccounts?.length" @click="createSalesInvoiceEmail(itemData)" label="Send file" icon="ti-email" class="p-button-raised" style="width: auto; height: auto;"></Button>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
<!--      <Button :label="$t('Save')" :disabled="disableSaveButton || ((selectedIssuedDateFinancialPeriodIsClosed || invoiceFinancialPeriodIsClosed) && !userIsAdminOrAccountant)" icon="pi pi-check" class="p-button-text" @click="saveItem" />-->
      <Button :label="$t('Save')" :disabled="disableSaveButton" icon="pi pi-check" class="p-button-text" @click="saveItem" />
    </template>
  </Modal>

<!--  <CustomerModal :visible="customerModal"-->
<!--                 :modalLayer="2"-->
<!--                 :item="customer"-->
<!--                 :taxes="taxes"-->
<!--                 @close="closeCustomerModal"-->
<!--                 @update-item="updateCustomer">-->
<!--  </CustomerModal>-->

<!--  <CarModal :visible="carModal"-->
<!--            :modalLayer="2"-->
<!--            :userId="selectedCustomer ? +selectedCustomer.id : null"-->
<!--            :item="car"-->
<!--            @close="closeCarModal"-->
<!--            @update-item="updateCar">-->
<!--  </CarModal>-->

  <SupplierModal :visible="supplierModal"
                 :modalLayer="2"
                 :item="supplier"
                 :taxes="taxes"
                 @close="closeSupplierModal"
                 @update-items="updateSupplier">
  </SupplierModal>

  <ConfirmDeleteModal
      :visible="confirmPaymentDeleteModal"
      @close="closeConfirmPaymentDeleteModal"
      @delete-item="deletePayment">
  </ConfirmDeleteModal>

  <EmailModal :visible="emailModal"
              :userEmailAccounts="userEmailAccounts"
              :item="emailData"
              @update-item="updateItem"
              @close="closeEmailModal">
  </EmailModal>

  <ConfirmModal :visible="confirmEditClosedFinancialPeriodDataModal"
                :text="'Financial period already closed. ' + $t('Are you sure you want to proceed?')"
                @close="closeConfirmEditClosedFinancialPeriodDataModal"
                @confirm="editClosedFinancialPeriodDataModal">
  </ConfirmModal>
  <ConfirmModal :visible="confirmSellingPriceIsTooLowModal"
                :text="'Positsiooni müügihind on madalam kui ostuhind. ' + $t('Are you sure you want to proceed?')"
                @close="closeConfirmSellingPriceIsTooLowModal"
                @confirm="saveItemAnyway">
  </ConfirmModal>
</template>

<script>
import httpClient from '@/services/http.services'
// import CarModal from '@/pages/cars/components/CarModal'
import SupplierModal from '@/pages/data_directory/components/SupplierModal'
import PaymentTypes from '@/translations/PaymentTypes'
import PositionHistoryButton from '@/components/PositionHistoryButton'
// import RequestPositionStateButton from '@/components/RequestPositionStateButton'
import RequestPositionStateButton from "@/pages/orders/components/RequestPositionStateButton";
// import RemoveButton from '@/components/DataTable/RemoveButton'
import PositionFixedQtyButton from '@/components/PositionFixedQtyButton'
// import ReduceButton from '@/components/DataTable/ReduceButton'
// import httpMixins from "@/mixins/httpMixins";
import formatMixins from "@/mixins/formatMixins";
import modalWithPositionsMixins from "@/mixins/modalWithPositionsMixins";
import permissionsMixins from "@/mixins/permissionsMixins";
import GetSalesInvoicePdfButton from "@/pages/finance/components/GetSalesInvoicePdfButton";
import EmailMessages from "@/translations/EmailMessages";
import settings from "@/settings";
import EmailModal from "@/pages/messages/components/EmailModal";
import constants from "@/constants";
import CustomerStatusHistoryButton from "@/pages/users/components/CustomerStatusHistoryButton";
import generateMixins from "@/mixins/generateMixins";
import overlayVisibilityMixins from "@/mixins/overlayVisibilityMixins";
import calculateMixins from "@/mixins/calculateMixins";
import webSocketMixins from "@/mixins/webSocketMixins";
import MessageBar from "@/components/MessageBar";
import Modal from "@/components/Modal";
import ConfirmModal from "@/components/ConfirmModal";
import showErrorsMixins from "@/mixins/showErrorsMixins";

export default {
  mixins: [ showErrorsMixins, formatMixins, modalWithPositionsMixins, permissionsMixins, generateMixins, overlayVisibilityMixins, calculateMixins, webSocketMixins ],
  components: {
    ConfirmModal,
    Modal,
    SupplierModal,
    PositionHistoryButton,
    RequestPositionStateButton,
    PositionFixedQtyButton,
    GetSalesInvoicePdfButton,
    EmailModal,
    CustomerStatusHistoryButton,
    MessageBar
  },
  emits: ['close', 'update-items', 'update-item'],
  name: 'SalesInvoiceModal',
  props: {
    userEmailAccounts: Array
  },
  data() {
    return {
      // exportInvoiceToSmartAccounts: settings.exportSalesInvoiceByDefault,
      exportInvoiceToSmartAccounts: false,
      exportInvoiceTogglerIsDisabled: false,
      smartAccountsItems: [
        // {code: '0010', description: 'Kaup 20%'},
        // {code: '0040', description: 'Teenus 20%'},
        // {code: '0070', description: 'Kauba eksport'},
        // {code: 'error_code', description: 'Error code'},

        // {code: '30121', description: 'Kaup 20%'},
        // {code: '30131', description: 'Teenus 20%'},
        // {code: '30120', description: 'Jooksvad kulumaterjalid'},

        {code: '30120', description: 'Jooksvad kulumaterjalid'},
        {code: '30121', description: 'Varuosad'},
        {code: '30123', description: 'Tulud ostetud kauba müügist EL-i  0%'},
        {code: '30131', description: 'Tulud teenuste müügist Eestis 20%(Sõiduauto remonditööd)'},
        {code: '30133', description: 'Tulud teenuste müügist EL-i 0%'},
        {code: '30441', description: 'Põhivara müügikasum'},
        {code: '30491', description: 'Muud äritulud'},
        // {code: '30491KK', description: 'Kliendikaart'},
        {code: '31091', description: 'Viivised 0,5% päevas'},
      ],
      disableDeletePaymentButtons: false,
      disableAddPaymentBtn: false,
      issuedDateIsValid: false,
      paymentDueDateIsValid: false,
      emailModal: false,
      emailData: {},
      dataIsSending: false,
      // modalDataIsSending: false,
      confirmPaymentDeleteModal: false,
      unpaidAmount: 0,
      payments: [],
      issued: null,
      payment_due: null,
      paymentTypes: [],
      exportInvoiceCodeInputIsDisabled: false,
    }
  },
  watch: {
    item(value) {
      // if (typeof value === 'object' && !Object.keys(value)?.length) {
      //   return
      // }

      if (!value) return false
      this.itemData = { ...value }

      this.summary.sum = value.sum
      this.summary.tax = value.tax
      this.summary.grand_total = value.grand_total

      if (value.order_id) {
        this.positionsEditingIsDisabled = true
      }

      this.customerDisabled = !!(value.customerDisabled || value.order_id)
      this.carDisabled = !!(value.carDisabled || value.order_id)

      // this.itemData.use_consumables = !!value.order_consumable

      // console.log(this.customerDisabled)

      // if (value.name) {
      //   this.itemData.have_name = true
      // }

      // if (value.recommendation) {
      //   this.addRecommendations = true
      //   this.itemData.recommendation = value.recommendation
      // }

      // console.log(value.customer)

      this.selectedCustomer = value.selectedCustomer ?? null
      this.selectedCar = value.selectedCar ?? null
      if (value.customer) {
        // this.customerData = { ...value.customer }
        this.selectedCustomer = { ...value.customer }

        // this.customers = []
        // this.customers.push(this.selectedCustomer)
        this.changeCustomer()
      }

      if (value.car) {
        this.selectedCar = { ...value.car }
      }

      if (value.issued) {
        this.issued = new Date(value.issued * 1000)
        if (this.$store.state.branchData?.financial_period_closed_due > value.issued) {
          this.invoiceFinancialPeriodIsClosed = true
          this.exportInvoiceToSmartAccounts = false
          if (!this.userIsAdminOrAccountant) {
            this.exportInvoiceTogglerIsDisabled = true
          }
          // this.disableClosedFinancialPeriodDataEditing()
        } else {
          this.invoiceFinancialPeriodIsClosed = false
        }
      } else {
        this.issued = new Date()
      }

      this.checkIssuedDateFinancialPeriodIsClosed()
      // this.checkFinancialPeriodIsClosed()
      this.checkIssuedDateIsValid()

      if (value.payment_due) {
        this.payment_due = new Date(value.payment_due * 1000)
      } else {
        // this.payment_due = value.issued ? new Date(value.issued * 1000) : new Date()
        // this.issuedDateIsValid = true
        // this.payment_due = value.issued ? this.calculateDayEndDate(new Date(value.issued * 1000)) : this.calculateDayEndDate(new Date())
        this.calculatePaymentDue()
      }

      this.checkPaymentDueDateIsValid()

      if (value.isNew) {
        this.showCarData = true
        this.itemData.penalty = this.$store.state.branchData.penalty ? +this.$store.state.branchData.penalty : null
      } else {
        this.showCarData = !!value.show_car_data
        this.itemData.penalty = value.penalty ? +value.penalty : null
      }

      if (value.order_consumable) {
        value.consumable = value.order_consumable
      }
      // console.log(value.order_consumable)
      this.constructConsumable(value)

      if (this.consumable && this.settings.useSmartAccounts) {
        const consumableSelectedSmartAccountsCode = this.findSmartAccountsItem(this.consumable)
        this.consumable.selectedSmartAccountsCode = consumableSelectedSmartAccountsCode ?? null
        this.consumable.accounting_code = consumableSelectedSmartAccountsCode?.code ?? null
      }
      // if (this.consumable) {
      //   const selectedSmartAccountsCode = this.settings.useSmartAccounts ? this.findSmartAccountsItem(this.consumable) : null
      //
      //   this.consumable.selectedSmartAccountsCode = selectedSmartAccountsCode
      //   this.consumable.accounting_code = selectedSmartAccountsCode ? selectedSmartAccountsCode.code : null
      //
      //   console.log(this.consumable)
      // }

      // this.useConsumables = !!value.use_consumables

      if (value.state) {
        this.selectedState = this.SalesInvoiceStates.find(state => state.num === value.state)
      } else {
        this.selectedState = this.SalesInvoiceStates[0]  // State 'New'
      }

      let positions = null
      if (value.positions?.length) {
        positions = value.positions
      } else if (value.order_positions) {
        positions = value.order_positions
      }

      if (positions?.length) {
        let positionSorting = 1
        this.positions = positions.map(position => {
          const selectedService = position.type === 1 ? this.findSelectedService(position) : null
          const selectedTax = this.findSelectedTax(position)
          // const selectedMaster = position.type === 1 ? this.findSelectedMaster(position) : null
          const selectedSupplier = position.type === 2 ? this.findSelectedSupplier(position) : null
          const selectedWarehouseItem = position.type === 3 ? this.findSelectedWarehouseItem(position) : null

          const selectedSmartAccountsCode = this.settings.useSmartAccounts && position.status === 1 ? this.findSmartAccountsItem(position) : null

          if (position.discount && +position.discount !== 0) {
            this.showDiscountColumn = true
          }

          // console.log(selectedSmartAccountsCode)
          let purchasePrice = 0
          if (selectedService && position.isNew) {
            purchasePrice = selectedService.purchase ? +selectedService.purchase : 0
          } else {
            purchasePrice = position.purchase_price ? +position.purchase_price : 0
          }

          return {
            selectedSmartAccountsCode: selectedSmartAccountsCode,
            accounting_code: selectedSmartAccountsCode ? selectedSmartAccountsCode.code : null,

            isUpdated: !!position.isUpdated,
            id: position.id,
            order_id: position.order_id,
            isNew: !!position.isNew,
            reduced: false,
            sorting: position.sorting ?? !!position.isNew ? positionSorting++ : null,
            type: position.type,
            name: position.name,
            code: position.code ? position.code : null,
            // raw_code: position.code ? this.generateRawCode(position.code) : null,
            raw_code: position.raw_code ? position.raw_code : null,
            qty: position.qty ? +position.qty : 0,
            is_decimal: position.type === 3 && position.warehouseItem?.is_decimal,
            fixed_qty: position.fixed_qty ? +position.fixed_qty : 0,
            purchase_price: purchasePrice,
            purchase_sum: position.purchase_sum ? +position.purchase_sum : 0,
            sell_price: position.sell_price ? +position.sell_price : 0,
            sell_price_fixed: !!(position.sell_price && position.type === 2),
            discount: position.discount && +position.discount !== 0 ? +position.discount : null,
            discount_sum: position.discount_sum && +position.discount_sum !== 0 ? +position.discount_sum : 0,
            selectedTax: selectedTax,
            tax_id: position.tax_id ?? selectedTax.id ?? null,
            tax_value: selectedTax.value ? +selectedTax.value : 0,
            tax_sum: position.tax_sum ? +position.tax_sum : 0,
            sum: position.sum ? +position.sum : 0,
            total: position.total ? +position.total : 0,
            // updated: false,
            state: position.state ? +position.state : position.type === 2 ? 1 : null,
            status: position.status,
            // service: position.service ? position.service : null,
            service_id: position.service_id,
            selectedService: selectedService,
            warehouse_item_id: position.warehouse_item_id,
            selectedWarehouseItem: selectedWarehouseItem,
            master_id: position.master_id,
            // selectedMaster: selectedMaster,
            supplier_id: position.supplier_id,
            selectedSupplier: selectedSupplier,
            request_state_history: position.request_state_history ? position.request_state_history : null,
            history: position.history ? position.history : null,
            created_at: position.created_at ? position.created_at : null,
            creator: position.creator ? position.creator : null,
            deleted_at: position.deleted_at ? position.deleted_at : null,
            deletedBy: position.deletedBy ? position.deletedBy : null,
            originalServiceId: position.service_id,
            originalPurchasePrice: position.purchase_price ? +position.purchase_price : 0,
            originalPurchaseSum: position.purchase_sum ? +position.purchase_sum : 0,
            originalSellPrice: position.sell_price ? +position.sell_price : 0,
            originalQty: position.qty ? +position.qty : 0,
            originalMasterId: position.master_id,
            // originalMaster: selectedMaster,
            originalSupplierId: position.supplier_id,
            originalSupplier: selectedSupplier,
            originalWarehouseItemId: position.warehouse_item_id,
            originalWarehouseItem: selectedWarehouseItem,
            // updated: true

            // suppliersData: position.type === 2 ? [] : null
          }
        }).sort((a, b) => a.sorting - b.sorting)
      }

      // if (value.payments?.length) {
      value.payments?.forEach(payment => {
        const paymentDate = payment.date ? new Date(payment.date * 1000) : new Date()
        const paymentData = {
          id: payment.id,
          type: payment.type,
          value: this.formatDecimal(payment.sum, false),
          date: paymentDate,
          cashbox_id: payment.cashbox_id ? payment.cashbox_id : null,
          created_at: payment.created_at ? payment.created_at : null,
          creator: payment.creator ? payment.creator : null,
          // cashbox_name: payment.cashbox ? payment.cashbox.name : null,
          cashBoxName: payment.cashbox ? payment.cashbox.name : null,
          dateIsValid: this.checkDateIsValid(paymentDate)
        }

        if (payment.type === 3 || payment.type === 5) { // If TYPE_BANK_CARD or TYPE_BANK_TRANSFER
          paymentData.selectedType = PaymentTypes.find(p => p.type === payment.type)
        } else { // Else if CASHBOX payment
          paymentData.selectedType = {...PaymentTypes[0], cashBoxName: paymentData.cashBoxName }
        }
        this.payments.push(paymentData)
      })
      this.calculateUnpaidAmount()
    },
    visible() {
      if (!this.visible) {
        this.submitted = false
        this.issuedDateIsValid = false
        this.paymentDueDateIsValid = false
        // this.exportInvoiceToSmartAccounts = settings.exportSalesInvoiceByDefault
        this.exportInvoiceToSmartAccounts = false
        this.exportInvoiceTogglerIsDisabled = false
        this.exportInvoiceCodeInputIsDisabled = false
      } else {
        if (settings.isDevMode) {
          this.exportInvoiceToSmartAccounts = false
          this.exportInvoiceTogglerIsDisabled = true
        } else if (settings.useSmartAccounts) {
          this.exportInvoiceToSmartAccounts = !!settings.exportSalesInvoiceByDefault
        }
        // this.exportInvoiceToSmartAccounts = true
        // this.exportInvoiceTogglerIsDisabled = false
      }
    },
    '$store.state.user.cashboxes'() {
      this.computePaymentTypes()
    },
    '$store.state.secondLayerIsOpened'() {
      if (!this.$store.state.secondLayerIsOpened) {
        if (this.emailModal) {
          this.closeEmailModal()
        }
        if (this.confirmPaymentDeleteModal) {
          this.closeConfirmPaymentDeleteModal()
        }
      }
    },
  },
  mounted() {
    // this.paymentTypes = PaymentTypes[this.$i18n.locale].filter(payment => payment.type !== 1)
    this.computePaymentTypes()
  },
  methods: {
    changeSmartAccountsItem(position) {
      // if (!position.selectedSmartAccountsCode) return false
      // position.updated = !position.isNew
      position.isUpdated = true
      position.accounting_code = position.selectedSmartAccountsCode?.code ?? null
    },
    findSmartAccountsItem(position) {
      const currentAccountingCode = position.accounting_code
      const smartAccountsItem = position.accounting_code ? this.smartAccountsItems.find(i => +i.code === +position.accounting_code) : null

      let selectedSmartAccountsCode = null
      if (smartAccountsItem) {
        position.accounting_code = smartAccountsItem.code
        selectedSmartAccountsCode = smartAccountsItem
      } else {
        const defaultSmartAccountsItem = this.findDefaultSmartAccountsItem(position)
        if (defaultSmartAccountsItem) {
          position.accounting_code = defaultSmartAccountsItem.code
          selectedSmartAccountsCode = defaultSmartAccountsItem
        }
      }
      if (+currentAccountingCode !== +position.accounting_code) {
        position.isUpdated = true
      }

      return selectedSmartAccountsCode
    },
    findDefaultSmartAccountsItem(position) {
      let selectedSmartAccountsCode = null
      // if (position.type === 1 || position.type === 4 || position.type === 5 || position.type === 6) {
      //   selectedSmartAccountsCode = this.smartAccountsItems.filter(i => +i.code === +this.settings.smartAccountsSettings.defaultSalesInvoiceCodes.product)?.[0]
      // } else if (position.type === 3) {
      //   selectedSmartAccountsCode = this.smartAccountsItems.filter(i => +i.code === +this.settings.smartAccountsSettings.defaultSalesInvoiceCodes.consumable)?.[0]
      // } else if (position.type === 2) {
      //   selectedSmartAccountsCode = null
      // }

      if (position.type === 1) {
        selectedSmartAccountsCode = this.smartAccountsItems.find(i => +i.code === +this.settings.smartAccountsSettings.defaultSalesInvoiceCodes.service)
      } else if (position.type === 2 || position.type === 3 || position.type === 4) {
        selectedSmartAccountsCode = this.smartAccountsItems.find(i => +i.code === +this.settings.smartAccountsSettings.defaultSalesInvoiceCodes.product)
      } else {
        selectedSmartAccountsCode = this.smartAccountsItems.find(i => +i.code === +this.settings.smartAccountsSettings.defaultSalesInvoiceCodes.consumable)
      }

      return selectedSmartAccountsCode

      // if (selectedSmartAccountsCode) {
      //   position.accounting_code = selectedSmartAccountsCode.code
      //   return selectedSmartAccountsCode
      // } else {
      //   position.accounting_code = null
      //   return null
      // }
    },
    checkPaymentDateIsValid(payment) {
      if (!payment) return false
      payment.dateIsValid = this.checkDateIsValid(payment.date)
    },
    changeIssuedDate() {
      if (!this.issued) return false
      this.issued = this.calculateDateWithCurrentTime(this.issued)

      this.checkIssuedDateIsValid()
      this.calculatePaymentDue()
      this.checkIssuedDateFinancialPeriodIsClosed()
    },
    checkIssuedDateFinancialPeriodIsClosed() {
      if (this.issued && this.$store.state.branchData?.financial_period_closed_due > +new Date(this.issued / 1000)) {
        this.selectedIssuedDateFinancialPeriodIsClosed = true
        this.disableClosedFinancialPeriodDataEditing()
      } else {
        this.selectedIssuedDateFinancialPeriodIsClosed = false
      }
    },
    // checkFinancialPeriodIsClosed() {
    //   console.log('checkFinancialPeriodIsClosed')
    //   if (this.issued && this.$store.state.branchData?.financial_period_closed_due > +new Date(this.issued / 1000)) {
    //     // this.selectedIssuedDateFinancialPeriodIsClosed = true
    //     this.invoiceFinancialPeriodIsClosed = true
    //     this.disableClosedFinancialPeriodDataEditing()
    //   } else {
    //     this.selectedIssuedDateFinancialPeriodIsClosed = false
    //     this.invoiceFinancialPeriodIsClosed = false
    //   }
    // },
    changePaymentDueDate() {
      if (!this.payment_due) return false
      this.payment_due = this.calculateDayEndDate(this.payment_due)
      this.checkPaymentDueDateIsValid()
    },
    checkIssuedDateIsValid() {
      this.issuedDateIsValid = this.checkDateIsValid(this.issued)
    },
    checkPaymentDueDateIsValid() {
      this.paymentDueDateIsValid = this.checkDateIsValid(this.payment_due)
    },
    checkDateIsValid(date) {
      if (!date) return false
      return !!(date instanceof Date && !isNaN(date))
    },
    calculatePaymentDue() {
      if (!this.issued || !this.issuedDateIsValid) return false

      if (this.selectedCustomer && this.selectedCustomer.days_to_pay) {
        this.payment_due = new Date(+this.issued + (this.selectedCustomer.days_to_pay * 24 * 60 * 60 * 1000))
      } else {
        this.payment_due = this.issued
      }
      this.checkPaymentDueDateIsValid()
      this.changePaymentDueDate()
    },
    createSalesInvoiceEmail(salesInvoice) {
      if (!this.selectedCustomer) return false

      const salesInvoiceMessage = EmailMessages.salesInvoice[salesInvoice.customer?.language] ?? EmailMessages.salesInvoice[settings.defaultLanguage]
      this.emailData = {
        isSalesInvoice: true,
        sales_invoice_id: salesInvoice.id,
        subject: salesInvoiceMessage?.subject + salesInvoice.number,
        sales_invoice_text: salesInvoiceMessage?.text,
        sent_at: salesInvoice.sent_at,
        file_path: salesInvoice.file_path,
        user_id: salesInvoice.user_id,
        to_email: this.selectedCustomer.type === 2 && this.selectedCustomer.company_name ? (this.selectedCustomer.invoice_email ?? this.selectedCustomer.company_email) : this.selectedCustomer.email
        // offer_link_text: settings.useOfferRegistrationLink && offerMessage ? offerMessage.linkText  : null,
      }

      // if (this.selectedCustomer.type === this.constants.userTypes.legal_entity && this.selectedCustomer.company_name) {
      //   if (this.selectedCustomer.invoice_email) {
      //     this.emailData.to_email = this.selectedCustomer.invoice_email
      //   } else if (this.selectedCustomer.company_email) {
      //     this.emailData.to_email = this.selectedCustomer.company_email
      //   }
      // } else if (this.selectedCustomer.email) {
      //   this.emailData.to_email = this.selectedCustomer.email
      // }
      // console.log(this.emailData.to_email)

      this.emailModal = true
      this.$store.commit('toggleSecondLayer', true)
    },
    closeEmailModal() {
      this.emailData = {}
      this.emailModal = false
      // this.submitted = false
      this.$store.commit('toggleSecondLayer', false)
    },
    computePaymentTypes() {
      this.paymentTypes = []
      if (this.$store.state.user?.cashboxes?.length) {
        this.$store.state.user.cashboxes.forEach(cashbox => {
          // const newLabel = PaymentTypes[0]?.label[this.$i18n.locale] + ' ' + '(' + cashbox.name + ')'
          const newLabel = PaymentTypes[0]?.label
          const paymentMethod = {
            type: 1,
            label: newLabel,
            cashBoxName: cashbox.name,
            cashboxId: cashbox.id ? cashbox.id : null
          }
          this.paymentTypes.push(paymentMethod)
        })
        const notCashPayments = PaymentTypes.filter(payment => payment.type !== 1)
        notCashPayments?.forEach(payment => this.paymentTypes.push(payment))
      } else {
        this.paymentTypes = PaymentTypes.filter(payment => payment.type !== 1)
      }
    },
    changePaymentValue(value, payment) {
      value = value && !isNaN(value) ? +value : 0
      payment.value = this.formatDecimal(value, false)
      // this.calculateUnpaidAmount()
      // Then try to get 'this.unpaidAmount' and if it > than this.summary.grand_total, change it to grand_total

      // if (this.unpaidAmount)


      // console.log(payment.value)

      // const balance = this.payments?.length ? this.summary.grand_total - (this.payments.reduce((sum, payment) => sum + payment.value, 0) - value) : 0
      const totalPaymentsSum = this.payments?.reduce((sum, payment) => sum + payment.value, 0)

      let balance = totalPaymentsSum ? ( this.summary.grand_total - (totalPaymentsSum - value) ) : 0
      balance = this.formatDecimal(balance, false)

      // const balance = this.payments?.length ? this.summary.grand_total - (this.payments.reduce((sum, payment) => sum + payment.value, 0) - value) : 0
      // if (balance < 0 && (value < balance || value > 0)) {
      //   setTimeout(() => {
      //     payment.value = balance
      //     this.calculateUnpaidAmount()
      //   }, 10)
      // } else if (balance > 0 && (value > balance || value < 0)) {
      //   setTimeout(() => {
      //     payment.value = balance
      //     this.calculateUnpaidAmount()
      //   }, 10)
      // } else {
      //   this.calculateUnpaidAmount()
      // }

      // console.log(!!(balance > 0 && (value > balance || value < 0)))
      // console.log(unpaid)

      if ( (balance < 0 && (value < balance || value > 0)) ||
          (balance > 0 && (value > balance || value < 0))
      ) {
        setTimeout(() => {
          payment.value = balance
          this.calculateUnpaidAmount()
        }, 10)
      } else {
        this.calculateUnpaidAmount()
      }

      // this.calculateUnpaidAmount()

      // setTimeout(() => {
      //   payment.value = balance
      //   this.calculateUnpaidAmount()
      // }, 10)

      // this.calculateUnpaidAmount() //Warning! need to delete?
    },
    addNewPayment() {
      if (this.disableAddPaymentBtn) return
      const payment = {
        isNew: true,
        // date: this.issued ? new Date(this.issued) : new Date(),
        date: new Date(),
        value: this.formatDecimal(this.unpaidAmount, false),
        dateIsValid: true,
      }

      this.payments.push(payment)
      this.calculateUnpaidAmount()
    },
    onDeletePaymentClick(index, paymentId) {
      if (paymentId) {
        this.paymentToDeleteId = paymentId
        this.paymentToDeleteIndex = index
        this.confirmPaymentDeleteModal = true
        this.$store.commit('toggleSecondLayer', true)
      } else {
        this.payments.splice(index, 1)
        this.calculateUnpaidAmount()
      }
    },
    async deletePayment() {
      this.disableDeletePaymentButtons = true
      const obj = {
        id: this.paymentToDeleteId,
        invoiceId: this.itemData.id,
        branch_timezone: this.$store.state.branchData?.timezone,
        // taxesArray: this.generateTaxesArray(),
        // grand_total: this.summary.grand_total,
      }

      try {
        const { status, data } = await httpClient.post('sales-invoice-payment/delete', obj )
        if (status === 200 && data) {
          if (data.success) {
            if (data.sales_invoice_state) {
              const salesInvoiceState = data.sales_invoice_state
              this.selectedState = this.SalesInvoiceStates.find(state => state.num === salesInvoiceState)
            }
            // this.$emit('update-items', this.itemData.id)
            this.$toast.add({severity:'success', detail: this.$t('Data deleted'), life: this.settings.toastLife});
            this.payments.splice(this.paymentToDeleteIndex, 1)
            this.closeConfirmPaymentDeleteModal()
            this.$emit('update-items', this.itemData.id)
            this.$emit('update-item', this.itemData.id)
            this.calculateUnpaidAmount()
            if (this.itemData.export_id || this.itemData.export_number) {
              this.itemData.changed_after_export = 1
            }

            if (this.itemData.order_id) {
              this.sendUpdateOrderWebSocket(this.itemData.order_id)
            }
          } else if (data.error) {
            this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
          }
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.disableDeletePaymentButtons = false
      }
    },
    closeConfirmPaymentDeleteModal() {
      this.confirmPaymentDeleteModal = false
      this.$store.commit('toggleSecondLayer', false)
      this.paymentToDeleteId = this.paymentToDeleteIndex = null
    },
    calculateUnpaidAmount() {
      if (this.payments?.length) {
        const paymentsTotalSum = this.payments.reduce((sum, payment) => sum + (+payment.value), 0)
        const unpaidPaymentsAmount = this.summary.grand_total - paymentsTotalSum
        this.unpaidAmount = this.formatDecimal(unpaidPaymentsAmount, false)
      } else {
        this.unpaidAmount = this.formatDecimal(this.summary.grand_total, false)
      }

      if (this.unpaidAmount === 0) {
        this.unpaidAmount = 0
      }
    },
    togglePaymentHistoryOverlay(event, id) {
      if (!event || !id) {
        return false
      }
      this.$refs['mpaymenth' + id.toString()].toggle(event);
    },
    updateItem() {
      const id = this.itemData?.id
      if (!id) return
      this.itemData.sent_at = new Date()
      // this.$emit('update-item', this.item.id)
      this.$emit('update-item', id)
    },
    // async deletePosition() {
    //   this.positionToDelete.status = 0
    //   this.updateConsumable()
    //   try {
    //     const { status} = await httpClient.post('order-position/delete', { positionId: this.positionToDelete.id, consumable: this.consumable, orderId: this.itemData.id })
    //     if (status === 200) {
    //       this.positionToDelete.status = 0
    //       this.positionToDelete.deleted_at = +new Date() / 1000
    //       this.positionToDelete.deletedBy = {
    //         first_name: this.$store.state.user.first_name,
    //         last_name: this.$store.state.user.last_name,
    //       }
    //       this.closeConfirmPositionDeleteModal()
    //       this.$toast.add({severity:'success', detail: this.$t('Data deleted'), life: this.settings.toastLife})
    //       // this.deletedPositions = true
    //       this.$emit('update-items', this.itemData.id)
    //     }
    //   } catch(err) {
    //     this.updateConsumable()
    //     this.positionToDelete.status = 1
    //     this.showError(err)
    //   } finally {
    //     this.updateSummary()
    //   }
    // },
    async saveItem() {
      this.submitted = true

      if ((this.isAnyActiveServicePosition && !this.selectedCustomer) ||
          !this.issued ||
          !this.payment_due ||
          this.payment_due < this.issued ||
          (this.issued && !this.issuedDateIsValid) ||
          (this.payment_due && !this.paymentDueDateIsValid) ||
          !this.selectedState ||
          // (this.useConsumables && this.consumable && (!this.consumable.name || !this.consumable.selectedTax)) ||
          (this.itemData.invoice_comment && this.itemData.invoice_comment.length > this.salesInvoiceCommentMaxChars) ||
          (this.itemData.staff_comment && this.itemData.staff_comment.length > this.salesInvoiceStaffCommentMaxChars) ||
          (this.itemData.recommendation && this.itemData.recommendation.length > this.salesInvoiceRecommendationMaxChars)) {
        // this.disableSaveButton = false
        // this.dataIsSending = false
        // return false
        // this.disableSaveButton = false
        // this.dataIsSending = false
        return false
      }

      let wrongInputs = false
      let positionSellingPriceIsLow = false

      // if (this.positions && this.positions.length) {
      this.positions?.filter(position => position.status === 1)?.forEach(position => {
        if (!position.name ||
            !position.sell_price ||
            !position.qty  ||
            !position.selectedTax ||
            (this.settings.useSmartAccounts && this.exportInvoiceToSmartAccounts && !position.selectedSmartAccountsCode)) {
          wrongInputs = true
        }
        if (position.type === 1 && !position.selectedService) {
          wrongInputs = true
        }
        if (position.type === 2 && !position.selectedSupplier) {
          wrongInputs = true
        }
        if (position.type === 3 && !position.selectedWarehouseItem) {
          wrongInputs = true
        }
        if (position.sell_price < position.purchase_price && !this.ignoreSellingPriceIsTooLow) {
          positionSellingPriceIsLow = true
        }
      })
      // }

      // let cashPaymentsAmount = 0
          // if (this.payments && this.payments.length) {
      this.payments?.forEach(payment => {
        if (!payment.selectedType || !payment.value || !payment.dateIsValid) {
          wrongInputs = true
        }
        // else if (payment.selectedType.type === 1) {
        //   cashPaymentsAmount += payment.value
        // }
      })
      // }
      // console.log(cashPaymentsAmount)

      if (wrongInputs) {
        // this.dataIsSending = false
        // this.disableSaveButton = false
        return false
      }

      if (positionSellingPriceIsLow) {
        this.openConfirmSellingPriceIsTooLowModal()
        return false
      }

      console.log(this.exportInvoiceToSmartAccounts)
      // if (this.exportInvoiceToSmartAccounts) {
      //   if ((this.selectedIssuedDateFinancialPeriodIsClosed || this.invoiceFinancialPeriodIsClosed) &&
      //       !this.editClosedFinancialPeriodDataConfirmed) {
      //     if (this.userIsAdminOrAccountant) {
      //       this.openConfirmEditClosedFinancialPeriodDataModal()
      //     }
      //     return false
      //   }
      // }

      this.dataIsSending = true
      this.disableSaveButton = true

      const salesInvoice = {
        id: this.itemData.id ? this.itemData.id : null,
        order_id: this.itemData.order_id ? this.itemData.order_id : null,
        user_id: this.selectedCustomer ? +this.selectedCustomer.id : null,
        car_id: this.selectedCar ? this.selectedCar.id : null,
        branch_id: this.$store.state.branchData ? this.$store.state.branchData.id : null,
        state: this.selectedState ? this.selectedState.num : null,
        penalty: this.itemData.penalty ? +this.itemData.penalty : null,
        show_car_data: this.showCarData,
        sum: this.formatDecimal(this.summary.sum, false),
        tax: this.formatDecimal(this.summary.tax, false),
        grand_total: this.formatDecimal(this.summary.grand_total, false),
        invoice_comment: this.itemData.invoice_comment ? this.itemData.invoice_comment : null,
        staff_comment: this.itemData.staff_comment ? this.itemData.staff_comment : null,
        use_consumables: this.useConsumables,
        recommendation: this.itemData.recommendation ?? null,
        // taxesArray: this.generateTaxesArray(),
        branch_timezone: this.$store.state.branchData?.timezone,
        exportInvoiceToSmartAccounts: this.exportInvoiceToSmartAccounts,
        // newPositions: this.generateNewPositionsData(),  // Sales invoice use order positions, so there is no $newPositions, only $updatedPositions
        updatedPositions: this.generateUpdatedPositionsData(),
        issued: this.issued ? +new Date(this.issued / 1000) : +new Date(Date.now() / 1000),
        payment_due: this.payment_due ? +new Date(this.payment_due / 1000) : +new Date(Date.now() / 1000),
        editClosedFinancialPeriodDataConfirmed: this.editClosedFinancialPeriodDataConfirmed,
        // cashPaymentsAmount
      }

      // if (this.issued) {
      //     const year = new Date(this.issued).getFullYear()
      //     const month = new Date(this.issued).getMonth()
      //     const day = new Date(this.issued).getDate()
      //     const issued = +new Date(year, month, day)
      //     salesInvoice.issued = issued / 1000
      // }
      //
      // if (this.payment_due) {
      //   const year = new Date(this.payment_due).getFullYear()
      //   const month = new Date(this.payment_due).getMonth()
      //   const day = new Date(this.payment_due).getDate()
      //   const payment_due = +new Date(year, month, day)
      //   salesInvoice.payment_due = payment_due / 1000
      // }

      let cashboxTransactionDescriptionString  = ''
      // const orderName = this.itemData.order?.name
      if (this.selectedCar) {
        const makeName = this.selectedCar.make ? this.selectedCar.make.name : null
        const modelName = this.selectedCar.model ? this.selectedCar.model.name : null
        const plateNumber = this.selectedCar.plate_number
        const year = this.selectedCar.year
        // if (orderName) {
        //   cashboxTransactionDescriptionString += orderName
        // }
        if (makeName) {
          cashboxTransactionDescriptionString += makeName
        }
        if (modelName) {
          if (makeName) {
            cashboxTransactionDescriptionString += ' '
          }
          cashboxTransactionDescriptionString += modelName
        }
        if (plateNumber) {
          if (makeName || modelName) {
            cashboxTransactionDescriptionString += ', '
          }
          cashboxTransactionDescriptionString += plateNumber
        }
        if (year) {
          if (makeName || modelName || plateNumber) {
            cashboxTransactionDescriptionString += ', '
          }
          cashboxTransactionDescriptionString += year
        }
      } else {
        cashboxTransactionDescriptionString = '-'
      }

      const newPayments = this.payments?.filter(payment => payment.isNew)
      if (newPayments?.length) {
        salesInvoice.newPayments = newPayments.map(payment => {
          return {
            type: payment.selectedType.type,
            sum: payment.value,
            state: payment.selectedType.type === 5 ? 1 : 3,
            date: payment.date ? +new Date(payment.date / 1000) : null ,
            unpaid_amount: payment.selectedType.type === 5 ? payment.value : 0,
            cashboxId: payment.selectedType.cashboxId ? payment.selectedType.cashboxId : null,
            orderId: this.item.order_id ? this.item.order_id : null,
            salesInvoiceId: this.item.id ? this.item.id : null,
            description: cashboxTransactionDescriptionString
          }
        })
      }

      // const updatedPositions = this.positions?.filter(position => position.updated === true)
      // if (updatedPositions?.length) {
      //   salesInvoice.updatedPositions = updatedPositions.map(position => {
      //     return {
      //       id: position.id,
      //       accounting_code: position.accounting_code
      //     }
      //   })
      // }

      // salesInvoice.newPositions = this.positions?.filter(position => position.isNew)

      if (this.useConsumables && this.consumable) {
        salesInvoice.consumable = {
          accounting_code: this.consumable.selectedSmartAccountsCode?.code ?? null
        }
      }

      if (this.itemData.id) {
        try {
          const { status, data } = await httpClient.post(`sales-invoice/update`, salesInvoice)
          // console.log(JSON.parse(data))
          if (status === 200 && data?.success) {
            this.$emit('update-items', this.itemData.id)
            this.$emit('update-item', this.itemData.id) // Update orderData
            // this.updateItem()
            this.$toast.add({severity:'success', detail: this.$t('Data updated'), life: this.settings.toastLife});
            this.close()

            if (this.exportInvoiceToSmartAccounts) {
              if (data?.export_error_message) {
                this.$toast.add({severity:'error', summary: 'Invoice export error', detail: data.export_error_message, life: this.settings.toastLife});
              }
            }

            if (this.itemData.order_id) {
              this.sendUpdateOrderWebSocket(this.itemData.order_id)
            }
          } else if (data?.error) {
            this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
          } else if (data?.financial_period_is_closed) {
            console.log(111)
            this.$store.commit('updateBranchDataVariable', 'financial_period_closed_due', data.financial_period)
            this.invoiceFinancialPeriodIsClosed = true
            // this.disableClosedFinancialPeriodDataEditing()
            this.openConfirmEditClosedFinancialPeriodDataModal()
            if (!this.userIsAdminOrAccountant) {
              this.disableClosedFinancialPeriodDataEditing()
            }
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.disableSaveButton = false
          this.dataIsSending = false
          this.editClosedFinancialPeriodDataConfirmed = false
          this.ignoreSellingPriceIsTooLow = false
        }
      } else {
        try {
          const { status, data } = await httpClient.post(`sales-invoice/create`, salesInvoice)
          if (status === 201 && data?.success) {
            this.itemData.id = data.id
            this.$emit('update-items', this.itemData.id)
            this.$emit('update-item')  // Update orderData
            this.$toast.add({severity:'success', detail: this.$t('Data saved'), life: this.settings.toastLife});
            this.close()

            if (this.exportInvoiceToSmartAccounts) {
              if (data?.export_error_message) {
                this.$toast.add({severity:'error', summary: 'Invoice export error', detail: data.export_error_message, life: this.settings.toastLife});
              }
            }
            if (this.itemData.order_id) {
              this.sendUpdateOrderWebSocket(this.itemData.order_id)
            }
          } else if (data?.error) {
            this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
          } else if (data?.invoice_already_created) {
            this.$emit('update-items', this.itemData.id)
            this.$emit('update-item')
            this.$toast.add({severity:'warn', detail: 'Invoice already created', life: this.settings.toastLife});
            this.close()
          } else if (data?.financial_period_is_closed) {
            this.$store.commit('updateBranchDataVariable', 'financial_period_closed_due', data.financial_period)
            this.invoiceFinancialPeriodIsClosed = true
            this.openConfirmEditClosedFinancialPeriodDataModal()
            if (!this.userIsAdminOrAccountant) {
              this.disableClosedFinancialPeriodDataEditing()
            }
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.disableSaveButton = false
          this.dataIsSending = false
          this.editClosedFinancialPeriodDataConfirmed = false
          this.ignoreSellingPriceIsTooLow = false
        }
      }
    },
  },
  computed: {
    selectedCustomerHasEmailAddress() {
      const user = this.selectedCustomer
      if (!user) return false
      if (user.type === this.constants.userTypes.legal_entity && user.company_name) {
        return !!(user.invoice_email || user.company_email)
      } else {
        return !!user.email
      }
    },
    // userIsAdminOrAccountant() {
    //   const superAdmin = this.constants.userRoles.superAdmin
    //   const admin = this.constants.userRoles.admin
    //   const accountant = this.constants.userRoles.accountant
    //
    //   return !!(this.$store.state.user.role === superAdmin || 
    //       this.$store.state.user.role === admin || 
    //       this.$store.state.user.role === accountant)
    // },
    havePositionsWithFixedQty() {
      return !!this.positions?.find(p => p.fixed_qty && +p.fixed_qty)
    },
    computedMinDateValue() {
      const now = new Date();
      const nowYear = now.getFullYear()
      const nowDay = now.getDate()
      const nowMonth = now.getMonth()

      let firstDate = new Date()

      if (this.$store.state.user.role === constants.userRoles.superAdmin ||
          this.$store.state.user.role === constants.userRoles.admin ||
          this.$store.state.user.role === constants.userRoles.accountant) {

        firstDate = new Date(nowYear - 1, nowMonth, nowDay)
      }

      return firstDate
    },
    computedWindowSize() {
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: 0, width: '95%', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '85%', marginTop: '15px'}
      } else {
        return {position: 'absolute', top: 0, width: '90%', maxWidth: '1600px'}
      }
    },
  },

}
</script>

<style scoped lang="scss">
.p-button-text {
  width: auto;
}

.radio-button-label {
  padding: 6px;
  margin-left: 0;
  cursor: pointer;
}

.dropdown-wrapper {
  display: flex;
}

.dropdown__select {
  width: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.dropdown__select--border-radius {
  width: 100%;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.dropdown__select--one-button-width {
  width: calc(100% - 2.357rem);
}

.dropdown__select--two-buttons-width {
  width: calc(100% - (2.357rem + 2.357rem));
}

.dropdown__buttons {
  display: flex;
}

.dropdown__edit-button {
  width: 2.357rem;
  border-radius: 0;
}

.dropdown__edit-button--rounded {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.dropdown__create-button{
  width: 2.357rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.table-wrapper {
  overflow-y: auto;
}

//.datatable > td {
//  padding: 0 !important;
//}

.positions-table {
  width: 100%;
  border-collapse: collapse;
  .table-body__name-input--accounting-code {
    //min-width: 90px;
    //width: 90px;
    min-width: 100px;
    width: 10vw;
  }
  .table-body__name-input--first {
    min-width: 100px;
    width: 10vw;
  }
  .table-body__name-input--code {
    min-width: 100px;
    width: 10vw;
  }
  .table-body__name-input--name {
    min-width: 130px;
    width: 15vw;
  }
  .table-body__qty-input {
    min-width: 75px;
    max-width: 120px;
  }
  .table-body__purchase-input {
    min-width: 50px;
    max-width: 110px;
  }
  .table-body__sell-input {
    min-width: 50px;
    max-width: 110px;
  }
  .table-body__discount-input {
    min-width: 50px;
    max-width: 110px;
  }
  .table-body__sum-input {
    min-width: 65px;
    max-width: 125px;
  }
  .table-body__tax-input {
    min-width: 60px;
    width: 5vw;
  }
  .table-body__total-input {
    min-width: 65px;
    max-width: 125px;
  }

  .table-summary__price {
    width: 90px;
  }
}

//td {
//  //padding: 6px !important;
//  background-color: red;
//}

.position-icon {
  padding: 4px 3px;
  display: flex;
  justify-content: center;
  &:hover {
    opacity: 0.7;
  }
  i {
    font-size: 1.1em;
  }
}


.payments-table-wrapper {
  overflow: auto;
  //padding-right: 4px;
  padding-bottom: 10px;
  .payments-table {
    border-collapse: collapse;
    width: 100%;
    tr {
      td {
        padding: 8px !important;
      }
    }
  }
}

</style>
