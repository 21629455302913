<template>
  <MessageBar v-if="itemData?.wasUpdatedByThirdParty"
              :text="'Attention! The price offer has been updated.'"
              :severity="'warn'"/>

  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">{{ itemData?.id ? 'Edit offer ' + itemData.number :  'New offer' }}</div>
    </template>

    <Spinner v-if="dataIsSending"></Spinner>
    <div v-else class="p-formgrid p-grid p-pt-3">
<!--      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-4 p-mb-5">-->
<!--        <div class="p-float-label p-input-icon-right">-->
<!--          <InputText if="offer_type" v-model="itemData.source" disabled autocomplete="off"></InputText>-->
<!--          <label for="offer_type">Offer source</label>-->
<!--        </div>-->
<!--      </div>-->

<!--      :class="{'p-invalid' : submitted && !selectedCustomer, 'dropdown__select&#45;&#45;two-buttons-width': selectedCustomer && (selectedCustomer.role === constants.userRoles.customer || $store.state.user.role === constants.userRoles.superAdmin)}"-->

      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-4 p-mb-5">
        <div class="p-float-label dropdown-wrapper">
          <CustomDropdown id="offer-customer"
                    :disabled="!!customerDisabled"
                    class="dropdown__select"
                    :class="{
                      'p-invalid': submitted && (isAnyActiveServicePosition && !selectedCustomer),
                      'dropdown__select--border-radius': customerDisabled,
                      'dropdown__select--one-button-width': userCanEditCustomer && !customerDisabled && !selectedCustomer,
                      'dropdown__select--two-buttons-width': userCanEditCustomer && !customerDisabled && selectedCustomer
                    }"
                    :searchData="customerSearchData"
                    v-model="selectedCustomer"
                    :options="customers"
                    filterPlaceholder="Search customer"
                    :emptyMessage="'Please enter 3 or more characters'"
                    :emptyFilterMessage="showCustomerMinCharsMessage ? 'Please enter 3 or more characters' : 'No results found'"
                    :filterFields="['first_name', 'last_name', 'full_phone_number', 'email', 'company_name', 'reg_number', 'company_full_phone_number', 'company_email']"
                    :filter="true"
                    :createButton="true"
                    :clearSearchData="!visible || clearCustomerSearchData"
                    :loading="customersDataIsLoading"
                    @change="changeCustomer"
                    @filter="searchCustomers"
                    @create-new="createCustomer"
                    :resetFilterOnHide="true"
                    :showClear="!customerDisabled">
            <template #value="slotProps">
              <div v-if="slotProps.value" class="p-d-flex p-align-center">
<!--                <i class="ti-user p-mr-2"></i>-->
                <CustomerStatusHistoryButton :customerStatus="slotProps.value.customerStatus" :isChangeable="false"/>
                <span v-if="slotProps.value.type === constants.userTypes.legal_entity && slotProps.value.company_name">{{ slotProps.value.company_name }} ({{ slotProps.value.first_name }} {{ slotProps.value.last_name }})</span>
                <span v-else>{{ slotProps.value.first_name }} {{ slotProps.value.last_name }}</span>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div v-if="slotProps.option" class="p-d-flex p-ai-center p-jc-between">
                <div class="p-d-flex p-align-center">
<!--                  <i class="ti-user p-mr-2"></i>-->
                  <CustomerStatusHistoryButton :customerStatus="slotProps.option.customerStatus" :isChangeable="false"/>
                  <span v-if="slotProps.option.type === constants.userTypes.legal_entity && slotProps.option.company_name">{{ slotProps.option.company_name }} ({{ slotProps.option.first_name }} {{ slotProps.option.last_name }})</span>
                  <span v-else>{{ slotProps.option.first_name }} {{ slotProps.option.last_name }}</span>
                </div>
                <div class="p-ml-1" v-if="slotProps.option.type === constants.userTypes.legal_entity && slotProps.option.company_name"><span v-if="slotProps.option.company_phone_code">+{{ slotProps.option.company_phone_code }}</span> {{ slotProps.option.company_phone_number }}</div>
                <div class="p-ml-1" v-else><span v-if="slotProps.option.phone_code">+{{ slotProps.option.phone_code }}</span> {{ slotProps.option.phone_number }}</div>
              </div>
            </template>
          </CustomDropdown>
          <div class="dropdown__buttons" v-show="!customerDisabled">
            <Button v-if="userCanEditCustomer && selectedCustomer" @click="editCustomer" class="dropdown__edit-button p-d-flex p-ai-center p-jc-center"><i class="ti-pencil"></i></Button>
            <Button @click="createCustomer" class="dropdown__create-button p-d-flex p-ai-center p-jc-center"><i class="ti-plus"></i></Button>
          </div>
          <label for="offer-customer">Customer<span v-show="isAnyActiveServicePosition" class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && (isAnyActiveServicePosition && !selectedCustomer)">{{ $t('Required field') }}</small>
      </div>

<!--      :class="{'dropdown__select&#45;&#45;border-radius': item.carDisabled || (!selectedCar && !selectedCustomer), 'dropdown__select&#45;&#45;one-button-width': selectedCustomer && !selectedCar, 'dropdown__select&#45;&#45;two-buttons-width': selectedCar && selectedCustomer}"-->
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-4 p-mb-5">
        <div class="p-float-label dropdown-wrapper">
          <CustomDropdown id="offer-car"
                    :disabled="!!carDisabled"
                    class="dropdown__select"
                    :class="{'dropdown__select--border-radius': carDisabled || (!selectedCar && !selectedCustomer), 'dropdown__select--one-button-width': ((selectedCustomer && !selectedCar) || (!selectedCustomer && selectedCar)) && !carDisabled, 'dropdown__select--two-buttons-width': selectedCar && selectedCustomer && !carDisabled}"
                    :searchData="carSearchData"
                    v-model="selectedCar"
                    :options="cars"
                    filterPlaceholder="Search car"
                    :emptyMessage="'Please enter 3 or more characters'"
                    :emptyFilterMessage="showCarMinCharsMessage ? 'Please enter 3 or more characters' : 'No results found'"
                    :filterFields="['plate_number', 'vin', 'model']"
                    :filter="true"
                    :createButton="!!selectedCustomer"
                    :clearSearchData="!visible || clearCarSearchData"
                    :loading="carsDataIsLoading"
                    @change="changeCar"
                    @filter="searchCars"
                    @create-new="createCar"
                    :showClear="!carDisabled">
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <div class="p-d-flex p-ai-center">
                  <div>
                    <i class="ti-car p-mr-2"></i>
                    <span v-if="slotProps.value.make">{{ slotProps.value.make.name }}</span> <span v-if="slotProps.value.model">{{ slotProps.value.model.name }}</span><span v-if="slotProps.value.plate_number">, {{ slotProps.value.plate_number }}</span>
                  </div>
                </div>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div v-if="slotProps.option" class="p-d-flex p-ai-center p-jc-between">
                <div>
                  <i class="ti-car p-mr-2"></i>
                  <span v-if="slotProps.option.make">{{ slotProps.option.make.name }}</span> <span v-if="slotProps.option.model">{{ slotProps.option.model.name }}</span><span v-if="slotProps.option.year">, {{ slotProps.option.year }} </span><span v-if="slotProps.option.vin">, {{ slotProps.option.vin }}</span>
                </div>
                <div class="p-ml-1" v-if="slotProps.option.plate_number">{{ slotProps.option.plate_number }}</div>
              </div>
            </template>
          </CustomDropdown>
          <div class="dropdown__buttons" v-show="!carDisabled">
            <Button v-if="selectedCar" @click="editCar" class="dropdown__edit-button p-d-flex p-ai-center p-jc-center" :class="{'dropdown__edit-button--rounded': selectedCar && !selectedCustomer}"><i class="ti-pencil"></i></Button>
            <Button v-if="selectedCustomer" @click="createCar" class="dropdown__create-button p-d-flex p-ai-center p-jc-center"><i class="ti-plus"></i></Button>
          </div>
          <label for="offer-car">Car <span v-if="customerCars?.length">({{ customerCars.length }})</span></label>
        </div>
      </div>


      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-4 p-mb-5">
        <div class="p-float-label">
          <CustomDropdown id="state"
                    :disabled="editingIsDisabled"
                    :class="{'p-invalid' : submitted && !itemData.selectedState}"
                    v-model="itemData.selectedState"
                    :options="OfferStates"
                    optionLabel="label"
                          :preventFilter="true"
                    :filter="false"
                    :showClear="false">
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <span class="state-badge" :class="slotProps.value.color">{{ slotProps.value.label[$i18n.locale] }}</span>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div>
                <span class="state-badge" :class="slotProps.option.color">{{ slotProps.option.label[$i18n.locale] }}</span>
              </div>
            </template>
          </CustomDropdown>
          <label for="state">State<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !itemData.selectedState">{{ $t('Required field') }}</small>
      </div>

<!--      //Name don't delete!!!!-->
<!--      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-4 p-mb-5">-->
<!--        <div class="p-float-label">-->
<!--          <InputText :disabled="editingIsDisabled" id="offer_name" :class="{'p-invalid' : submitted && !itemData.name}" v-model="itemData.name" autocomplete="off"/>-->
<!--          <label for="offer_name">Name<span class="warning-color">*</span></label>-->
<!--        </div>-->
<!--        <small class="p-invalid" v-if="submitted && !itemData.name">{{ $t('Required field') }}</small>-->
<!--      </div>-->

      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-4 p-mb-5">
        <div class="p-float-label">
<!--          @input="checkValidUntilDateIsValid"-->
<!--          @blur="checkValidUntilDateIsValid"-->
          <Calendar
              @date-select="changeValidUntilDate"
              @blur="changeValidUntilDate"
              @show="toogleDropdownOverlayVisibility(true)"
              @hide="toogleDropdownOverlayVisibility(false)"
              :showButtonBar="true"
              :dateFormat="settings.dateFormat"
              id="valid_until"
              :disabled="item.state === 5"
              :class="{'p-invalid' : (itemData.valid_until && !validUntilDateIsValid) || (submitted && !itemData.valid_until)}"
              v-model="itemData.valid_until"
              :showIcon="true"
              :selectOtherMonths="true"
              autocomplete="off"/>
          <label for="order_start">Valid until<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !itemData.valid_until">{{ $t('Required field') }}</small>
        <small class="p-invalid" v-if="itemData.valid_until && !validUntilDateIsValid">{{ $t('Invalid date format') }}</small>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-4 p-mb-5">
        <div class="p-float-label">
<!--          :class="{'p-invalid' : submitted && !selectedResponsible}"-->
          <CustomDropdown id="responsible"
                              :disabled="editingIsDisabled"
                              v-model="selectedResponsible"
                              :clearSearchData="!visible"
                              :options="responsibles"
                              optionLabel="full_name"
                              :filter="true"
                              :showClear="true">
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <span>{{ slotProps.value.full_name }}</span>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div>
                <span>{{ slotProps.option.full_name }}</span>
              </div>
            </template>
          </CustomDropdown>
<!--          <label for="responsible">Responsible<span class="warning-color">*</span></label>-->
          <label for="responsible">Responsible</label>
        </div>
<!--        <small class="p-invalid" v-if="submitted && !selectedResponsible">{{ $t('Required field') }}</small>-->
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-4 p-mb-5">
        <div class="p-float-label">
          <MultiSelect :disabled="editingIsDisabled"
                       @change="changeSelectedMasters"
                       @show="toogleDropdownOverlayVisibility(true)"
                       @hide="toogleDropdownOverlayVisibility(false)"
                       id="masters"
                       v-model="selectedMasters"
                       :clearSearchData="!visible"
                       :options="mastersData"
                       optionLabel="full_name"
                       :filter="true"
                       display="chip"/>
          <label for="masters">Masters</label>
        </div>
      </div>
      <!--      //Name don't delete!!!!-->
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-4 p-mb-5">
        <div class="p-float-label">
          <InputText id="order_name"
                     :disabled="editingIsDisabled"
                     v-model.trim="itemData.name"
                     autocomplete="off"/>
          <label for="order_name">Name</label>
        </div>
        <!--        <small class="p-invalid" v-if="submitted && !itemData.name">{{ $t('Required field') }}</small>-->
      </div>
<!--      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 add-position-buttons" :class="{'p-mb-5': noPositions && !computedDeletedPositionsExists }">-->
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 add-position-buttons">
        <Button :disabled="editingIsDisabled" @click="addNewPosition(1)" v-tooltip.top="'Add service'" class="p-button-outlined add-position-button">
          <i class="ti-plus p-mr-1"></i>
          <i class="ti-hummer"></i>
        </Button>
        <Button :disabled="editingIsDisabled" @click="addNewPosition(2)" v-tooltip.top="'Add request'" class="p-button-outlined add-position-button">
          <i class="ti-plus p-mr-1"></i>
          <i class="ti-truck"></i>
        </Button>
        <Button :disabled="editingIsDisabled" @click="addNewPosition(3)" v-tooltip.top="'Add warehouse item'"  class="p-button-outlined add-position-button">
          <i class="ti-plus p-mr-1"></i>
          <i class="ti-shopping-cart"></i>
        </Button>
        <Button :disabled="editingIsDisabled" @click="addNewPosition(4)" v-tooltip.top="'Add free position'" class="p-button-outlined add-position-button">
          <i class="ti-plus p-mr-1"></i>
          <i class="ti-pencil"></i>
        </Button>
        <Button :disabled="editingIsDisabled" @click="addMaintenancePositions" v-tooltip.top="'Add maintenance (oil, filters)'" class="p-button-outlined add-position-button--text">
          <i class="ti-plus p-mr-1"></i>
          <span>{{ $t('Maintenance') }} 1</span>
        </Button>
        <Button :disabled="editingIsDisabled" @click="addSecondMaintenancePositions" v-tooltip.top="'Add maintenance (brakes)'" class="p-button-outlined add-position-button--text p-mr-5">
          <i class="ti-plus p-mr-1"></i>
          <span>{{ $t('Maintenance') }} 2</span>
        </Button>
        <div class="p-d-flex p-ai-center p-mt-3 p-mb-3 p-mr-3">
          <div class="p-d-flex p-ai-center">
            <InputSwitch :disabled="editingIsDisabled" :id="'use_consumables_modal' + isSecondModal ? '--second-modal' : ''" v-model="useConsumables" @change="toggleConsumable"/>
          </div>
          <label :for="'use_consumables_modal' + isSecondModal ? '--second-modal' : ''" class="switch-label" :class="{'pointer' : !editingIsDisabled}">Use consumables?</label>
        </div>
        <div class="p-d-flex p-ai-center p-mt-3 p-mb-3" v-show="!noPositions">
          <div class="p-d-flex p-ai-center">
            <InputSwitch :disabled="editingIsDisabled || positionsEditingIsDisabled || (isAnyPositionWithDiscount && showDiscountColumn)" id="show-discount-toggler-order-modal" v-model="showDiscountColumn"/>
          </div>
          <label for="show-discount-toggler-order-modal" class="switch-label" :class="{'pointer': !editingIsDisabled && !positionsEditingIsDisabled && (!isAnyPositionWithDiscount || (isAnyPositionWithDiscount && !showDiscountColumn))}">Show discount</label>
        </div>
<!--        <div class="p-d-flex p-ai-center p-mt-3 p-mb-3" v-if="computedDeletedPositionsExists">-->
<!--         <div class="p-d-flex p-ai-center">-->
<!--           <InputSwitch id="show_deleted_modal" v-model="showDeleted"/>-->
<!--         </div>-->
<!--          <label for="show_deleted_modal" class="switch-label pointer">Show Deleted?</label>-->
<!--        </div>-->
      </div>
      <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 table-wrapper" :class="{'p-mb-5' : showPositionsTable || computedDeletedPositionsExists }">
        <div v-if="computedDeletedPositionsExists">
          <a @click.prevent="showDeleted = !showDeleted" class="p-link">
            <i style="font-size: 0.9em" class="pi p-mr-1" :class="showDeleted ? 'pi-minus' :'pi-plus'"></i>
            <span>{{ showDeleted ? 'Hide deleted' : 'Show deleted' }}</span>
          </a>
        </div>
        <table v-if="showPositionsTable" class="p-datatable p-datatable-striped p-datatable-sm p-datatable-hoverable-rows positions-table">
          <thead class="p-datatable-thead">
          <tr>
            <th class="table-title__icon"></th>
            <th class="table-title__name--first"></th>
            <th class="table-title__name--second"></th>
            <th class="table-title__name--last">Name</th>
            <th class="table-title__qty">Qty</th>
            <th class="table-title__purchase">Buy</th>
            <th class="table-title__sell">Sell</th>
            <th class="table-title__discount" v-show="showDiscountColumn">Dis.(%)</th>
            <th class="table-title__margin" v-show="showMarginColumn">Mgn.%</th>
            <th class="table-title__sum">Sum</th>
            <th class="table-title__tax">Tax</th>
            <th class="table-title__total">Total</th>
            <th class="table-title__info"></th>
<!--            <th class="table-title__info"></th>-->
            <th class="table-title__delete-btn"></th>
          </tr>
          </thead>
          <tbody class="p-datatable-tbody">
          <tr v-for="(position, index) of positions"
              :key="index">
<!--            @mousedown="rowOnMouseDown"-->
            <template v-if="position.status || showDeleted">
              <td :data-index="index"
                  draggable="true"
                  @dragstart="handleDragStart"
                  @dragenter="handleDragEnter"
                  @dragleave="handleDragLeave"
                  @dragover="handleDragOver"
                  @drop="handleDrop"
                  class="grabbable" :class="{'deleted-position-td': position.status === 0, 'new-item' : position.isNew, 'inactive-position-td': position.status === 2}">
                <div class="p-d-flex p-ai-center grabbable">
<!--                  <div class="p-mr-2 grabbable" v-if="position.sorting">{{ position.sorting }}</div>-->
                  <i class="grabbable" :class="{'ti-hummer':position.type === 1, 'ti-truck':position.type === 2, 'ti-shopping-cart':position.type === 3, 'ti-pencil':position.type === 4}"></i>
                </div>
              </td>
              <template v-if="position.type === 1">
                <td :class="{'deleted-position-td': position.status === 0, 'new-item' : position.isNew, 'inactive-position-td': position.status === 2}">
                  <CustomDropdown :class="{'p-invalid warning-background' : submitted && !position.selectedService}"
                                      :disabled="position.status === 0 || editingIsDisabled"
                                      class="table-body__name-input--first"
                                      v-model="position.selectedService"
                                      @change="changeService(position)"
                                      @filter="searchServices($event, position)"
                                      placeholder="Select service"
                                      :options="position.servicesData || servicesData"
                                      :filter="true"
                                      optionLabel="name"
                                      :showClear="false">
                    <template #value="slotProps">
                      <div v-if="slotProps.value">
                        <span>{{ slotProps.value.name }}</span>
                      </div>
                      <!--                  <span v-else style="visibility: hidden">.</span>-->
                      <span v-else>Select service</span>
                    </template>
                    <template #option="slotProps">
                      <div>
                        <span>{{ slotProps.option.name }}</span>
                      </div>
                    </template>
                  </CustomDropdown>

                </td>
                <td :class="{'deleted-position-td': position.status === 0, 'new-item' : position.isNew, 'inactive-position-td': position.status === 2}">
                  <CustomDropdown class="table-body__name-input--code"
                                      :disabled="position.status === 0 || editingIsDisabled  || !selectedMasters?.length"
                                      v-model="position.selectedMaster"
                                      @change="changeMaster(position)"
                                      placeholder="Select master"
                                      :options="selectedMasters"
                                      :filter="false"
                                      optionLabel="full_name"
                                      :showClear="true">
                    <template #value="slotProps">
                      <div v-if="slotProps.value">
                        <span>{{ slotProps.value.full_name }}</span>
                      </div>
                      <!--                  <span v-else style="visibility: hidden">.</span>-->
                      <span v-else>Select master</span>
                    </template>
                    <template #option="slotProps">
                      <div>
                        <span>{{ slotProps.option.full_name }}</span>
                      </div>
                    </template>
                  </CustomDropdown>
                </td>
<!--                <td :class="{'deleted-position-td': position.status === 0, 'new-item' : position.isNew, 'inactive-position-td': position.status === 2}">-->
<!--                  <InputText :disabled="!position.selectedService || position.status === 0 || editingIsDisabled"-->
<!--                             v-model="position.name"-->
<!--                             :placeholder="$t('Name')"-->
<!--                             @input="updatePosition(position, false, 'name')"-->
<!--                             :class="{'p-invalid' : submitted && position.selectedService && !position.name}"-->
<!--                             class="table-body__name-input&#45;&#45;name"-->
<!--                             autocomplete="off"/>-->
<!--                </td>-->
              </template>

              <template v-else-if="position.type === 2">
                <td :class="{'deleted-position-td': position.status === 0, 'new-item' : position.isNew, 'inactive-position-td': position.status === 2}">
                  <div class="dropdown-wrapper table-body__name-input--first">
                    <CustomDropdown class="dropdown__select"
                                    :class="{'p-invalid warning-background' : submitted && !position.selectedSupplier, 'dropdown__select--border-radius' : position.status === 0 || editingIsDisabled, 'dropdown__select--one-button-width' : position.status !== 0 && !editingIsDisabled }"
                                    :searchData="position.searchData"
                                    ref="selectedSupplierDropdown"
                                    :disabled="position.status === 0 || editingIsDisabled"
                                    v-model="position.selectedSupplier"
                                    :options="position.suppliersData || suppliersData"
                                    filterPlaceholder="Find supplier"
                                    :filterFields="['name', 'company_name', 'reg_number']"
                                    :filter="true"
                                    :createButton="false"
                                    @change="changeSupplier(position)"
                                    @filter="searchSuppliers($event, position)"
                                    placeholder="Select supplier"
                                    :showClear="false">
                      <template #value="slotProps">
                        <div v-if="slotProps.value">
                          <!--                          <div class="p-d-flex p-ai-center">-->
                          <div>{{ slotProps.value.company_name }} <span v-if="slotProps.value.name">({{ slotProps.value.name }})</span></div>
                          <!--                          </div>-->
                        </div>
                        <!--                        <span v-else style="visibility: hidden">.</span>-->
                        <span v-else>Select supplier</span>
                      </template>
                      <template #option="slotProps">
                        <div>{{ slotProps.option.company_name }} <span v-if="slotProps.option.name">({{ slotProps.option.name }})</span></div>
                      </template>
                    </CustomDropdown>
                    <!--                    <Button v-if="position.status !== 0 && !editingIsDisabled" @click="addNewSupplier(position)" class="dropdown__create-button p-d-flex p-ai-center p-jc-center"><i class="ti-plus"></i></Button>-->
                    <Button v-if="position.status !== 0 && !editingIsDisabled" @click="addNewSupplier(position)" class="dropdown__create-button p-d-flex p-ai-center p-jc-center"><i class="ti-plus"></i></Button>
                  </div>
                </td>
                <td :class="{'deleted-position-td': position.status === 0, 'new-item' : position.isNew, 'inactive-position-td': position.status === 2}">
                  <InputText :placeholder="$t('Code')" :disabled="position.status === 0 || editingIsDisabled" v-model="position.code" @input="updatePosition(position, $event.value, 'code')" class="table-body__name-input--code" autocomplete="off"/>
                </td>
<!--                <td :class="{'deleted-position-td': position.status === 0, 'new-item' : position.isNew, 'inactive-position-td': position.status === 2}">-->
<!--                  <InputText :placeholder="$t('Name')"-->
<!--                             :disabled="position.status === 0 || editingIsDisabled"-->
<!--                             :class="{'p-invalid' : submitted && !position.name}"-->
<!--                             v-model="position.name"-->
<!--                             @input="updatePosition(position, false, 'name')"-->
<!--                             class="table-body__name-input&#45;&#45;name"-->
<!--                             autocomplete="off"/>-->
<!--                </td>-->
              </template>


              <template v-if="position.type === 3">
                <td :class="{ 'new-item' : position.isNew, 'deleted-position-td':  position.status === 0, 'inactive-position-td': position.status === 2 }">
                  <div class="dropdown-wrapper table-body__name-input--first">
                    <CustomDropdown class="dropdown__select"
                                        :class="{'p-invalid warning-background' : submitted && !position.selectedWarehouseItem,  'dropdown__select--border-radius' : position.status === 0 || editingIsDisabled || !userCanAddNewWarehouseItem, 'dropdown__select--one-button-width' : position.status !== 0 && !editingIsDisabled && userCanAddNewWarehouseItem }"
                                        :searchData="position.searchData"
                                        :disabled="position.status === 0 || editingIsDisabled"
                                        v-model="position.selectedWarehouseItem"
                                        @change="changeWarehouseItem(position)"
                                        @filter="searchWarehouseItems($event, position)"
                                        placeholder="Select item"
                                        :options="position.warehouseItemsData || warehouseItemsData"
                                        :filter="true"
                                        :filterFields="['code', 'raw_code', 'name']"
                                        :showClear="false">
                      <template #value="slotProps">
                        <div v-if="slotProps.value">
                          <span>{{ slotProps.value.name }}</span>
                        </div>
                        <span v-else>Select item</span>
                      </template>
                      <template #option="slotProps">
                        <div>
                          <div>{{ slotProps.option.name }}<span v-if="slotProps.option.code">, Code: {{ slotProps.option.code }}</span></div>
                          <span class="p-mt-1" style="font-size: 0.9rem">Qty: {{ formatDecimal(slotProps.option.qty) }}, Reserved: {{ formatDecimal(slotProps.option.reserved_qty) }}, Available: {{ formatDecimal(slotProps.option.qty - slotProps.option.reserved_qty) }}</span>
                        </div>
                      </template>
                    </CustomDropdown>
                    <Button v-if="position.status !== 0 && !editingIsDisabled && userCanAddNewWarehouseItem" @click="addNewWarehouseItem(position)" class="dropdown__create-button p-d-flex p-ai-center p-jc-center"><i class="ti-plus"></i></Button>
                  </div>
                </td>
                <td :class="{ 'new-item' : position.isNew, 'deleted-position-td':  position.status === 0, 'inactive-position-td': position.status === 2 }">
                  <InputText :placeholder="$t('Code')" disabled v-model.trim="position.code" @input="updatePosition(position, $event.value, 'code')" class="table-body__name-input--code" autocomplete="off"/>
                </td>
<!--                <td :class="{ 'new-item' : position.isNew, 'deleted-position-td':  position.status === 0, 'inactive-position-td': position.status === 2 }">-->
<!--                  <InputText :disabled="!position.selectedWarehouseItem || position.status === 0 || editingIsDisabled"-->
<!--                             v-model.trim="position.name"-->
<!--                             :placeholder="$t('Name')"-->
<!--                             @input="updatePosition(position, false, 'name')"-->
<!--                             :class="{'p-invalid' : submitted && position.selectedWarehouseItem && !position.name}"-->
<!--                             class="table-body__name-input&#45;&#45;name"-->
<!--                             autocomplete="off"/>-->
<!--                </td>-->
              </template>

              <template v-if="position.type === 4">
                <td :class="{'deleted-position-td': position.status === 0, 'new-item' : position.isNew, 'inactive-position-td': position.status === 2}">
                  <InputText disabled class="table-body__name-input--first"></InputText>
                </td>
                <td :class="{'deleted-position-td': position.status === 0, 'new-item' : position.isNew, 'inactive-position-td': position.status === 2}">
                  <InputText :placeholder="$t('Code')" :disabled="position.status === 0  || editingIsDisabled" v-model="position.code" @input="updatePosition(position, $event.value, 'code')" class="table-body__name-input--code" autocomplete="off"/>
                </td>
<!--                <td :class="{'deleted-position-td': position.status === 0, 'new-item' : position.isNew, 'inactive-position-td': position.status === 2}">-->
<!--                  <InputText :placeholder="$t('Name')"-->
<!--                             :disabled="position.status === 0  || editingIsDisabled"-->
<!--                             v-model="position.name"-->
<!--                             @input="updatePosition(position, false, 'name')"-->
<!--                             :class="{'p-invalid' : submitted && !position.name}"-->
<!--                             class="table-body__name-input&#45;&#45;name"-->
<!--                             autocomplete="off"/>-->
<!--                </td>-->
              </template>
              <td :class="{ 'new-item' : position.isNew, 'deleted-position-td':  position.status === 0, 'inactive-position-td': position.status === 2 }">
                <InputText :placeholder="$t('Name')"
                           :disabled="(position.type === 1 && !position.selectedService) ||
                                        (position.type === 3 && !position.selectedWarehouseItem) ||
                                        position.status === 0 ||
                                        editingIsDisabled ||
                                        positionsEditingIsDisabled"
                           v-model.trim="position.name"
                           @input="updatePosition(position, false, 'name')"
                           :class="{'p-invalid warning-background' : submitted && !position.name}"
                           class="table-body__name-input--name"
                           autocomplete="off"/>
              </td>
              <td :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0, 'inactive-position-td': position.status === 2 }">
                <InputNumber v-if="position.type === 2"
                             :locale="computedNumberInputLocale"
                             :disabled="position.status === 0 || editingIsDisabled"
                             @input="updatePosition(position, $event.value, 'qty', 99999, 0)"
                             v-model="position.qty"
                             :inputClass="{'p-invalid warning-background' : submitted && !position.qty && position.status !== 0}"
                             class="table-body__qty-input"
                             :min="0"
                             :max="99999"
                             :showButtons="position.status !== 0 && !editingIsDisabled"
                             autocomplete="off"/>
                <InputNumber v-else
                             :locale="computedNumberInputLocale"
                             :disabled="position.status === 0 || editingIsDisabled"
                             @input="updatePosition(position, $event.value, 'qty', 99999, -99999)"
                             v-model="position.qty"
                             :inputClass="{'p-invalid warning-background' : submitted && !position.qty}"
                             class="table-body__qty-input"
                             mode="decimal"
                             :min="-99999"
                             :max="99999"
                             :showButtons="position.status !== 0 && !editingIsDisabled"
                             :minFractionDigits="position.type === 3 && !position.is_decimal ? 0 : 2"
                             :maxFractionDigits="position.type === 3 && !position.is_decimal ? 0 : 2"
                             autocomplete="off"/>
              </td>
              <td :class="{'deleted-position-td': position.status === 0, 'new-item' : position.isNew, 'inactive-position-td': position.status === 2}">
<!--                :inputClass="{'p-invalid' : submitted && (position.sell_price < position.purchase_price)}"-->
                <InputNumber @input="updatePosition(position, $event.value, 'purchase_price', 1000000, 0, true)"
                             :locale="computedNumberInputLocale"
                             :disabled="(position.type === 1 || position.type === 3 || position.status === 0) || editingIsDisabled"
                             v-model="position.purchase_price"
                             :inputClass="{'p-invalid warning-background' : position.sell_price && +position.sell_price && position.sell_price < position.purchase_price}"
                             class="table-body__purchase-input"
                             mode="decimal"
                             :min="0"
                             :max="1000000"
                             :minFractionDigits="2"
                             :maxFractionDigits="4"
                             autocomplete="off"/>
              </td>
              <td :class="{'deleted-position-td': position.status === 0, 'new-item' : position.isNew, 'inactive-position-td': position.status === 2}">
<!--                :inputClass="{'p-invalid' : submitted && (!position.sell_price || position.sell_price < position.purchase_price)}"-->
                <InputNumber @input="updatePosition(position, $event.value, 'sell_price', 1000000, 0)"
                             :locale="computedNumberInputLocale"
                             :disabled="(position.type === 3 && !position.selectedWarehouseItem) || position.type === 1 || position.status === 0  || editingIsDisabled"
                             v-model="position.sell_price"
                             :inputClass="{'p-invalid warning-background' : (submitted && !position.sell_price) || (position.sell_price && +position.sell_price && position.sell_price < position.purchase_price)}"
                             class="table-body__sell-input"
                             mode="decimal"
                             :min="0"
                             :max="1000000"
                             :minFractionDigits="2"
                             :maxFractionDigits="4"
                             autocomplete="off"/>
              </td>
              <td v-show="showDiscountColumn" :class="{'deleted-position-td': position.status === 0, 'new-item' : position.isNew, 'inactive-position-td': position.status === 2}">
                <InputNumber :locale="computedNumberInputLocale"
                             @input="updatePosition(position, $event.value, 'discount', 100, 0)"
                             :disabled="position.status === 0  || editingIsDisabled"
                             v-model="position.discount"
                             class="table-body__discount-input"
                             mode="decimal"
                             :min="0"
                             :max="100"
                             :minFractionDigits="2"
                             :maxFractionDigits="2"
                             autocomplete="off"/>
              </td>
              <td v-show="showMarginColumn"
                  :class="{'deleted-position-td': position.status === 0, 'new-item' : position.isNew, 'inactive-position-td': position.status === 2}">
                <InputNumber :locale="computedNumberInputLocale"
                             v-show="position.type !== 1"
                             @input="marginOnInput(position, $event.value)"
                             :disabled="position.status === 0 || editingIsDisabled || positionsEditingIsDisabled || (position.discount && +position.discount !== 0)"
                             v-model="position.margin"
                             class="table-body__margin-input"
                             mode="decimal"
                             :min="0"
                             :max="1000000"
                             :minFractionDigits="0"
                             :maxFractionDigits="2"
                             autocomplete="off"/>
              </td>
              <td :class="{'deleted-position-td': position.status === 0, 'new-item' : position.isNew, 'inactive-position-td': position.status === 2}">
                <InputNumber :locale="computedNumberInputLocale" v-model="position.sum" mode="decimal" class="table-body__sum-input" disabled :minFractionDigits="2" :maxFractionDigits="4"/>
              </td>
              <td :class="{'deleted-position-td': position.status === 0, 'new-item' : position.isNew, 'inactive-position-td': position.status === 2}">
                <CustomDropdown @change="changeTax(position, $event.value)"
                                    class="table-body__tax-input"
                                    :class="{'p-invalid warning-background' : (submitted && !position.selectedTax)}"
                                    :disabled="position.status === 0 || editingIsDisabled"
                                    v-model="position.selectedTax"
                                    :options="taxesData"
                                    :filter="false"
                                    optionLabel="value"
                                    :showClear="false">
                  <template #value="slotProps">
                    <div v-if="slotProps.value">
                      <span>{{ slotProps.value.value }}%</span>
                    </div>
                    <span v-else style="visibility: hidden">.</span>
                  </template>
                  <template #option="slotProps">
                    <div>
                      <span>{{ slotProps.option.value }}% ({{ slotProps.option.name }})</span>
                    </div>
                  </template>
                </CustomDropdown>
              </td>
              <td :class="{'deleted-position-td': position.status === 0, 'new-item' : position.isNew, 'inactive-position-td': position.status === 2}">
                <InputNumber :locale="computedNumberInputLocale" v-model="position.total" class="table-body__total-input" mode="decimal" disabled :minFractionDigits="2" :maxFractionDigits="4"/>
              </td>
              <td class="p-p-0" :class="{'deleted-position-td': position.status === 0, 'new-item' : position.isNew, 'inactive-position-td': position.status === 2}">
                <PositionHistoryButton :position="position"/>
              </td>
<!--              <td class="p-p-0" :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0, 'updated-item': position.reduced, 'inactive-position-td': position.status === 2 }">-->
<!--                <a v-if="position.status !== 0" @click.prevent="togglePositionIsActive(position)" href="#" class="p-link table-link-icon">-->
<!--                  <i v-if="position.status === 1" class="pi pi-eye-slash"></i>-->
<!--                  <i v-else-if="position.status === 2" class="pi pi-eye"></i>-->
<!--                </a>-->
<!--              </td>-->
              <td :class="{'deleted-position-td': position.status === 0, 'new-item' : position.isNew, 'inactive-position-td': position.status === 2}">
                <!--                <DeleteButton v-if="position.status !== 0 && !editingIsDisabled" @click="confirmPositionDelete(position)"/>-->
                <DeleteButton v-if="position.status !== 0 && !position.isNew && !editingIsDisabled" @click="confirmPositionDelete(position)"/>
                <RemoveButton v-else-if="position.status !== 0 && position.isNew && !editingIsDisabled" @click="removePosition(index)"/>
                <RestoreDeletedButton v-show="false" v-else-if="!editingIsDisabled && position.status === 0" @click="restoreDeletedOfferPosition(position)"/>
              </td>
            </template>
          </tr>

          <tr v-if="useConsumables && consumable">
            <td>
              <i class="ti-spray"></i>
            </td>
            <td></td>
            <td></td>
            <td>
              <InputText :class="{'p-invalid warning-background' : submitted && useConsumables && consumable && !consumable.name}"
                         :disabled="editingIsDisabled"
                         v-model="consumable.name"
                         class="table-body__name-input--name"
                         autocomplete="off"/>
            </td>
            <td>
              <InputNumber :locale="computedNumberInputLocale" v-model="consumable.qty" class="table-body__qty-input" mode="decimal" disabled :min="2" :max="999" :minFractionDigits="2" :maxFractionDigits="2" autocomplete="off"/>
            </td>
            <td>
<!--              <InputNumber :value="consumable.purchase_price" -->
<!--              :locale="computedNumberInputLocale"-->
<!--                           class="table-body__purchase-input" -->
<!--                           mode="decimal" -->
<!--                           disabled -->
<!--                           :min="0" -->
<!--                           :minFractionDigits="2" -->
<!--                           :maxFractionDigits="4" -->
<!--                           autocomplete="off"/>-->
            </td>
            <td>
              <InputNumber v-model="consumable.sell_price"
                           :locale="computedNumberInputLocale"
                           disabled
                           class="table-body__sell-input"
                           mode="decimal"
                           :min="0"
                           :minFractionDigits="2"
                           :maxFractionDigits="4"
                           autocomplete="off"/>
            </td>
            <td v-show="showDiscountColumn">
              <InputNumber :locale="computedNumberInputLocale"
                           :disabled="editingIsDisabled"
                           @input="updatePosition(consumable, $event.value, 'discount', 100, 0)"
                           v-model="consumable.discount"
                           class="table-body__discount-input"
                           mode="decimal"
                           :min="0"
                           :max="100"
                           :minFractionDigits="2"
                           :maxFractionDigits="2"
                           autocomplete="off"/>
            </td>
            <td v-if="showMarginColumn"></td>
            <td>
              <InputNumber :locale="computedNumberInputLocale"
                           v-model="consumable.sum"
                           class="table-body__sum-input"
                           mode="decimal"
                           disabled
                           :minFractionDigits="2"
                           :maxFractionDigits="4"/>
            </td>
            <td>
              <CustomDropdown @change="changeTax(consumable, $event.value)"
                              class="table-body__tax-input"
                              :disabled="editingIsDisabled"
                              :class="{'p-invalid warning-background' : (submitted && useConsumables && consumable && !consumable.selectedTax)}"
                              v-model="consumable.selectedTax"
                              :options="taxes"
                              :filter="false"
                              optionLabel="value"
                              :showClear="false">
                <template #value="slotProps">
                  <div v-if="slotProps.value">
                    <span>{{ slotProps.value.value }}%</span>
                  </div>
                  <span v-else style="visibility: hidden">.</span>
                </template>
                <template #option="slotProps">
                  <div>
                    <span>{{ slotProps.option.value }}% ({{ slotProps.option.name }})</span>
                  </div>
                </template>
              </CustomDropdown>
            </td>
            <td>
              <InputNumber :locale="computedNumberInputLocale" v-model="consumable.total" class="table-body__total-input" mode="decimal" disabled :minFractionDigits="2" :maxFractionDigits="4"/>
            </td>
            <td></td>
            <td></td>
<!--            <td></td>-->
          </tr>
          <tr style="background-color: inherit">
            <td style="padding-right: 10px; line-height: 1.5; text-align: right;" colspan="13">
              <div class="p-text-right p-d-flex p-flex-column p-align-end">
                <div class="p-d-flex p-ai-center">
                  <div>Sum without tax: </div>
                  <div class="table-summary__price">{{ formatCurrency(summary.sum) }}</div>
                </div>
                <div class="p-d-flex p-ai-center">
                  <div>Tax: </div>
                  <div class="table-summary__price">{{ formatCurrency(summary.tax) }}</div>
                </div>
                <div class="p-d-flex p-ai-center p-text-bold">
                  <div>Grand total: </div>
                  <div class="table-summary__price">{{ formatCurrency(summary.grand_total) }}</div>
                </div>
              </div>
            </td>

          </tr>
          </tbody>
        </table>
      </div>

<!--      <div class="p-formgrid p-grid p-pt-5">-->
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
<!--        <div class="p-float-label p-input-icon-right" >-->
<!--          <i class="ti-comment-alt"></i>-->
<!--          <Textarea id="customer_comment" class="textarea-vertical-resize" :disabled="$store.state.user.role !== constants.userRoles.superAdmin" v-model="itemData.customer_comment" :rows="itemData.customer_comment?.length > 200 ? 4 : 2" :autoResize="true" autocomplete="off"/>-->
<!--          <label for="customer_comment">Customer comment</label>-->
<!--        </div>-->
        <LimitedCharsTextarea :submitted="submitted"
                              :disabled="$store.state.user.role !== constants.userRoles.superAdmin"
                              :rows="2"
                              v-model="itemData.customer_comment"
                              :maxChars="customerCommentMaxChars"
                              :id="'offer_modal_customer_comment'"
                              :label="'Customer comment'"/>
        <div class="p-mt-2 p-mb-3" v-if="!!itemData.comments_history?.find(comment => comment.type === 1)" >
          <span>{{ $t('Change history') }}: </span>
          <a @click.prevent="toggleCustomerCommentsHistoryOverlay($event, itemData.id)" v-if="itemData.comments_history && itemData.comments_history.find(comment => comment.type === 1)" href="#" class="p-link table-link-icon">
            <i class="ti-time"></i>
            <span style="font-size: 0.9em; position: relative; top: -1px;" class="p-ml-1">{{ itemData.comments_history.filter(comment => comment.type === 1).length }}</span>
          </a>
          <span v-if="itemData.parts_by" class="p-ml-2">Parts by: <span v-if="itemData.parts_by === 1">Workshop</span><span v-if="itemData.parts_by === 2">Customer</span></span>
        </div>
        <OverlayPanel v-if="itemData.id" :ref="'mch' + itemData.id">
          <div v-for="(history, index) of (itemData.comments_history).filter(comment => comment.type === 1)?.sort((a, b) => b.created_at - a.created_at)" :key="index" class="p-mb-2 p-mr-3">
            <div class="p-mb-2">
              <span v-if="history.created_at" >{{ formatDate(history.created_at) }},</span> <span v-if="history.creator && history.creator.first_name">{{ history.creator.first_name }}</span> <span v-if="history.creator && history.creator.last_name">{{ history.creator.last_name }}</span>
            </div>
            <div style="font-size: 0.92em; max-width: 600px">
              <span v-if="history.comment">{{ history.comment }}</span>
              <i v-else class="ti-minus"></i>
            </div>
            <hr v-if="index !== itemData.comments_history.filter(comment => comment.type === 1).length - 1">
          </div>
        </OverlayPanel>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
<!--        <div class="p-float-label p-input-icon-right" >-->
<!--          <i class="ti-comment-alt"/>-->
<!--          <Textarea id="staff_comment" class="textarea-vertical-resize" v-model="itemData.staff_comment" rows="2" :autoResize="true" autocomplete="off"/>-->
<!--          <label for="staff_comment">Internal comment</label>-->
<!--        </div>-->
        <LimitedCharsTextarea :submitted="submitted"
                              :rows="2"
                              v-model="itemData.staff_comment"
                              :maxChars="internalCommentMaxChars"
                              :id="'offer_modal_staff_comment'"
                              :label="'Internal comment'"/>
        <div class="p-mt-2 p-mb-3" v-if="itemData.comments_history?.find(comment => comment.type === 2)">
          <span>{{ $t('Change history') }}: </span>
          <a @click.prevent="toggleStaffCommentsHistoryOverlay($event, item.id)" href="#" class="p-link table-link-icon">
            <i class="ti-time"></i>
            <span style="font-size: 0.9em; position: relative; top: -1px;" class="p-ml-1">{{ itemData.comments_history.filter(comment => comment.type === 2)?.length }}</span>
          </a>
        </div>
        <OverlayPanel v-if="itemData.id" :ref="'msh' + itemData.id">
          <div v-for="(history, index) of (itemData.comments_history).filter(comment => comment.type === 2).sort((a, b) => b.created_at - a.created_at)" :key="index" class="p-mb-2 p-mr-3">
            <div class="p-mb-2">
              <span v-if="history.created_at" >{{ formatDate(+history.created_at) }},</span> <span v-if="history.creator && history.creator.first_name">{{ history.creator.first_name }}</span> <span v-if="history.creator && history.creator.last_name">{{ history.creator.last_name }}</span>
            </div>
            <div style="font-size: 0.92em; max-width: 600px">
              <span v-if="history.comment">{{ history.comment }}</span>
              <i v-else class="ti-minus"></i>
            </div>
            <hr v-if="index !== itemData.comments_history.filter(comment => comment.type === 2).length - 1">
          </div>
        </OverlayPanel>
      </div>
<!--      </div>-->

      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
<!--        <div class="p-float-label p-input-icon-right">-->
<!--          <i class="ti-comment-alt" />-->
<!--          <Textarea class="textarea-vertical-resize" :class="{'warning-color':isWarning}" id="additional_information" v-model.trim="itemData.additional_information" rows="2" :autoResize="true" autocomplete="off"/>-->
<!--          <label for="additional_information">Additional information</label>-->
<!--        </div>-->
        <LimitedCharsTextarea :submitted="submitted"
                              :rows="2"
                              v-model="itemData.additional_information"
                              :maxChars="offerAdditionalInformationMaxChars"
                              :id="'offer_modal_additional_information'"
                              :label="'Additional information'"/>
        <div class="p-d-flex p-ai-center p-mt-3">
          <div class="p-d-flex p-ai-center">
            <InputSwitch id="information_is_warning" v-model="isWarning" @change="itemData.is_warning = !itemData.is_warning"/>
          </div>
          <label for="information_is_warning" class="switch-label pointer">Additional information is important</label>
        </div>

      </div>

      <div v-if="item.childOrder?.number" class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
        <div >Order created: <router-link :to="{ path: `/orders/${item.childOrder.number}` }" target="_blank">{{ item.childOrder.number }}</router-link></div>
      </div>

      <div v-if="selectedCar?.plate_number" class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
        <span class="p-mr-2">{{ $t('Car data') }}:</span>
        <a target="_blank" :href="'https://eteenindus.mnt.ee/public/soidukTaustakontroll.jsf?regMark=' + selectedCar.plate_number">
          <i style="font-size: 0.85rem" class="pi pi-external-link"></i>
          {{ selectedCar.plate_number }}
        </a>
      </div>
    </div>
    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" :disabled="disableSaveButton" icon="pi pi-check" class="p-button-text" @click="saveItem" />
<!--      <Button :label="'Check taxes'" :disabled="disableSaveButton" icon="pi pi-check" class="p-button-text" @click="checkTaxes" />-->
    </template>
  </Modal>

  <ConfirmDeleteModal
      :visible="confirmPositionDeleteModal"
      :modalDataIsLoading="modalDataIsLoading"
      @close="closeConfirmPositionDeleteModal"
      @delete-item="deletePosition"
      :data="positionToDelete.name">
  </ConfirmDeleteModal>

  <CustomerModal :visible="customerModal"
                 :isSecondModal="isSecondModal"
                 :modalLayer="2"
                 :item="customer"
                 :taxes="taxes"
                 @close="closeCustomerModal"
                 @update-item="updateCustomer">
  </CustomerModal>

  <CarModal :visible="carModal"
            :modalLayer="2"
            :userId="selectedCustomer ? +selectedCustomer.id : null"
            :item="car"
            @close="closeCarModal"
            @update-item="updateCar">
  </CarModal>

  <SupplierModal :visible="supplierModal"
                 :modalLayer="2"
                 :item="supplier"
                 :taxes="taxes"
                 @close="closeSupplierModal"
                 @update-item="updateSupplier">
  </SupplierModal>

  <WarehouseItemModal :visible="warehouseItemModal"
                      :item="warehouseItem"
                      @close="closeWarehouseItemModal"
                      @update-item="updateWarehouseItem">
  </WarehouseItemModal>
  <ConfirmModal :visible="confirmSellingPriceIsTooLowModal"
                :text="'Positsiooni müügihind on madalam kui ostuhind. ' + $t('Are you sure you want to proceed?')"
                @close="closeConfirmSellingPriceIsTooLowModal"
                @confirm="saveItemAnyway">
  </ConfirmModal>
</template>

<script>
import httpClient from '@/services/http.services'
import OfferStates from '@/translations/states/OfferStates'
import CustomerModal from '@/pages/users/components/CustomerModal'
import CarModal from '@/pages/cars/components/CarModal'
import SupplierModal from '@/pages/data_directory/components/SupplierModal'
import PositionHistoryButton from '@/components/PositionHistoryButton'
import formatMixins from '@/mixins/formatMixins'
import generateMixins from '@/mixins/generateMixins'
import permissionsMixins from '@/mixins/permissionsMixins'
import RemoveButton from '@/components/DataTable/RemoveButton'
// import httpMixins from "@/mixins/httpMixins";
import modalWithPositionsMixins from "@/mixins/modalWithPositionsMixins";
import Spinner from "@/components/Spinner";
import WarehouseItemModal from "@/pages/warehouse/components/WarehouseItemModal";
import RestoreDeletedButton from "@/components/DataTable/RestoreDeletedButton";
import MessageBar from "@/components/MessageBar";
import CustomerStatusHistoryButton from "@/pages/users/components/CustomerStatusHistoryButton";
import overlayVisibilityMixins from "@/mixins/overlayVisibilityMixins";
import calculateMixins from "@/mixins/calculateMixins";
import webSocketMixins from "@/mixins/webSocketMixins";
import showErrorsMixins from "@/mixins/showErrorsMixins";
import sortingMixins from "@/mixins/sortingMixins";

export default {
  mixins: [ formatMixins, generateMixins, permissionsMixins, showErrorsMixins, modalWithPositionsMixins, overlayVisibilityMixins, calculateMixins, webSocketMixins, sortingMixins ],
  components: {  CustomerModal, CarModal, SupplierModal, PositionHistoryButton, RemoveButton, Spinner, WarehouseItemModal, RestoreDeletedButton, MessageBar, CustomerStatusHistoryButton },
  emits: ['close', 'update-items', 'update-item', 'change-updated-item-id', 'update-customer'],
  name: 'OfferModal',
  data() {
    return {
      marginTopSpace: 0,
      isWarning: false,
      dataIsSending: false,
      modalDataIsLoading: false,
      validUntilDateIsValid: false,
      // taxesArray: [],
    }
  },
  watch: {
    visible(boolean) {
      if (!boolean) {
        this.validUntilDateIsValid = false
        this.marginTopSpace = 0
      }
      // if (!this.visible) {
      //
      //   // this.itemData.staff_comment = null
      // }
    },
    '$store.state.updatedOfferId'(id) {
      if (!id) return false

      if (id === this.itemData?.id) {
        this.itemData.wasUpdatedByThirdParty = true
        this.marginTopSpace = 30
        // this.editingIsDisabled = true
        // this.customerDisabled = true
        // this.carDisabled = true
        // this.disableSaveButton = true
      }
      // this.$emit('change-updated-item-id', id)
      this.$emit('update-item', id)

      setTimeout(() => {
        this.$store.commit('changeUpdatedOfferId', null)
      }, 100)
    },
    item(value) {
      // if (typeof value === 'object' && !Object.keys(value)?.length) {
      //   console.log(1)
      //   return
      // }
      if (!value) return false
      this.itemData = { ...value }

      this.summary.sum = value.sum
      this.summary.tax = value.tax
      this.summary.grand_total = value.grand_total

      // if (value.state === 5 && this.$store.state.user.role !== constants.userRoles.superAdmin && this.$store.state.user.role !== constants.userRoles.admin) {
      //   this.editingIsDisabled = true
      // }
      if (value.state === 5) {
        this.editingIsDisabled = true
      }

      this.customerDisabled = !!(value.customerDisabled || value.state === 5)
      this.carDisabled = !!(value.carDisabled || value.state === 5)

      this.isWarning = !!value.is_warning

      if (value.name) {
        this.itemData.have_name = true
      }

      if (value.valid_until) {
        this.itemData.valid_until = new Date(value.valid_until * 1000)
      } else {
        // const offerExpireDate = this.$store.state.branchData?.offer_expire ?? 30
        if (this.$store.state.branchData?.offer_expire) {
          if (value.created_at) {
            this.itemData.valid_until = new Date((value.created_at * 1000) + (this.$store.state.branchData.offer_expire * 24 * 60 * 60 * 1000))
          } else {
            this.itemData.valid_until = new Date(+new Date() + (this.$store.state.branchData.offer_expire * 24 * 60 * 60 * 1000))
          }
        } else {
          // this.itemData.valid_until = new Date(+new Date())
          this.itemData.valid_until = new Date()
        }
      }


      this.changeValidUntilDate()
      // this.checkValidUntilDateIsValid()

      if (value.customer) {
        this.selectedCustomer = { ...value.customer }
        this.customers = [{...value.customer}]
        // this.customers = []
        // this.customers.push(this.selectedCustomer)
        this.changeCustomer()
      }

      if (value.state === 5) {
        this.OfferStates = OfferStates.filter(state => state.num === 5)
        this.itemData.selectedState = this.OfferStates[0]
      } else {
        this.OfferStates = OfferStates.filter(state => state.num !== 5)
        this.itemData.selectedState = this.OfferStates[0]
      }

      if (value.responsible) {
        this.selectedResponsible = {
          id: value.responsible.id,
          full_name: (value.responsible.first_name) + ' ' + (value.responsible.last_name)
        }
      } else {
        this.selectedResponsible = {
          id: this.$store.state.user.id,
          full_name: (this.$store.state.user.first_name) + ' ' + (this.$store.state.user.last_name)
        }
      }
      if (value.car) {
        this.selectedCar = this.carData = { ...value.car }
        // this.originalSelectedCarId = value.car.id
      }

      // if (value.masters) {
      //   const sortedMasters = [ ...value.masters ].sort((a, b) => a.id - b.id)
      //   this.selectedMasters = sortedMasters.map(item => {
      //     return {id: item.user.id, full_name: (item.user.first_name || '') + ' ' + (item.user.last_name || '')}
      //   })
      // }
      if (value.masters) {
        const sortedMasters = value.masters.sort((a, b) => a.id - b.id)

        this.selectedMasters = sortedMasters.map(master => {
          const masterInMastersDataArray = this.mastersData.find(m => m.id === master.user?.id)
          if (!masterInMastersDataArray) {
            this.mastersData.push({id: master.user.id, full_name: (master.user.first_name) + ' ' + (master.user.last_name)})
          }
          return {id: master.user.id, full_name: (master.user.first_name) + ' ' + (master.user.last_name)}
        })
      }

      if (value.state) {
        this.itemData.selectedState = this.OfferStates.find(state => state.num === value.state)
      } else {

        this.itemData.selectedState = this.OfferStates[0]
      }
      this.constructConsumable(value)

      if (value.positions?.length) {
        let positionSorting = 1
        this.positions = value.positions.map(position => {
          const selectedService = position.type === 1 ? this.findSelectedService(position) : null
          const selectedTax = this.findSelectedTax(position)
          const selectedMaster = position.type === 1 ? this.findSelectedMaster(position) : null
          const selectedSupplier = position.type === 2 ? this.findSelectedSupplier(position) : null
          const selectedWarehouseItem = position.type === 3 ? this.findSelectedWarehouseItem(position) : null

          if (position.discount && +position.discount !== 0) {
            this.showDiscountColumn = true
          }

          let purchasePrice = 0
          if (selectedService && position.isNew) {
            purchasePrice = selectedService.purchase ? +selectedService.purchase : 0
          } else {
            purchasePrice = position.purchase_price ? +position.purchase_price : 0
          }

          return {
            id: position.id,
            isNew: !!position.isNew,
            type: position.type,
            sorting: position.sorting ?? !!position.isNew ? positionSorting++ : null,
            name: position.name,
            code: position.code ? position.code : null,
            // raw_code: position.code ? this.generateRawCode(position.code) : null,
            raw_code: position.raw_code ? position.raw_code : null,
            qty: position.qty ? +position.qty : 0,
            is_decimal: position.type === 3 && position.warehouseItem?.is_decimal,
            // purchase_price: position.purchase_price ? +position.purchase_price : 0,
            purchase_price: purchasePrice,
            purchase_sum: position.purchase_sum ? +position.purchase_sum : 0,
            sell_price: position.sell_price ? +position.sell_price : 0,
            discount: position.discount && +position.discount !== 0 ? +position.discount : null,
            discount_sum: position.discount_sum && +position.discount_sum !== 0 ? +position.discount_sum : 0,
            margin: position.type === 1 ? null : this.calculatePositionMargin(position),
            // sum: (position.sell_price - (((position.discount || 0) / 100) * position.sell_price)) * position.qty,
            // total: ((position.sell_price - (((position.discount || 0) / 100) * position.sell_price)) * position.qty) * (((position.tax?.value || 0) / 100) + 1),
            selectedTax: selectedTax,
            tax_id: position.tax_id,
            tax_value: position.tax_value,
            tax_sum: position.tax_sum ? +position.tax_sum : 0,
            sum: position.sum ? +position.sum : 0,
            total: position.total ? +position.total : 0,
            updated: false,
            state: position.state ? +position.state : null,
            status: position.status,
            // service: position.service ? position.service : null,
            service_id: position.service_id,
            selectedService: selectedService,
            warehouse_item_id: position.warehouse_item_id,
            selectedWarehouseItem: selectedWarehouseItem,
            master_id: position.master_id,
            selectedMaster: selectedMaster,
            supplier_id: position.supplier_id,
            selectedSupplier: selectedSupplier,
            request_state_history: position.request_state_history ? position.request_state_history : null,
            history: position.history ? position.history : null,
            created_at: position.created_at ? position.created_at : null,
            creator: position.creator ? position.creator : null,
            deleted_at: position.deleted_at ? position.deleted_at : null,
            deletedBy: position.deletedBy ? position.deletedBy : null,
            originalServiceId: position.service_id,
            originalPurchasePrice: position.purchase_price ? +position.purchase_price : 0,
            originalPurchaseSum: position.purchase_sum ? +position.purchase_sum : 0,
            originalSellPrice: position.sell_price ? +position.sell_price : 0,
            originalQty: position.qty ? +position.qty : 0,
            originalMasterId: position.master_id,
            originalMaster: selectedMaster,
            originalSupplierId: position.supplier_id,
            originalSupplier: selectedSupplier,
            originalWarehouseItemId: position.warehouse_item_id,
            originalWarehouseItem: selectedWarehouseItem,
          }
        })
      }
      // this.updateConsumable()
      // this.updateSummary()
    },
  },
  methods: {
    checkTaxIsValid(position) {
      const dateBefore = this.itemData?.id ? this.itemData.created_at : Date.now()
      return !(position.selectedTax.valid_until && position.selectedTax.valid_until < dateBefore)
    },
    calculateValidDueDate() {
      console.log(this.$store.state.branchData.offer_expire)
      if (!this.itemData.valid_until && this.$store.state.branchData.offer_expire) {

        this.itemData.valid_until = new Date(+ + (this.$store.state.branchData.offer_expirey * 24 * 60 * 60 * 1000))
      }
    },
    updateItem() {
      // this.$emit('update-items', this.item.id)
      // if (!userIsNew) {
      const id = this.itemData?.id
      if (!id) return
      this.$emit('update-item', id)
      this.sendUpdateOfferWebSocket(id)
      // }
    },
    // changePaymentDueDate() {
    //   if (!this.payment_due) return false
    //   this.payment_due = this.calculateDayEndDate(this.payment_due)
    //   this.checkValidUntilDateIsValid()
    // },
    changeValidUntilDate() {
      if (!this.itemData.valid_until) return false
      this.itemData.valid_until = this.calculateDayEndDate(this.itemData.valid_until)
      this.checkValidUntilDateIsValid()
    },
    checkValidUntilDateIsValid() {
      this.validUntilDateIsValid = this.checkDateIsValid(this.itemData?.valid_until)
    },
    checkDateIsValid(date) {
      if (!date) return false
      return !!(date instanceof Date && !isNaN(date))
    },
    async deletePosition() {
      // if (this.itemData.selectedState?.num !== 1) {
      this.modalDataIsLoading = true
      // }

      this.positionToDelete.status = 0

      if (this.positionToDelete.type === 1) {
        this.updateConsumable()
      }
      this.updateSummary()

      // let taxesArray = null
      // if (this.itemData.selectedState?.num !== 1) {
      //   taxesArray = this.generateTaxesArray()
      // }

      const obj = {
        positionId: this.positionToDelete.id,
        consumable: this.consumable,
        offerId: this.itemData.id,
        sum: this.summary.sum,
        tax: this.summary.tax,
        grand_total: this.summary.grand_total,
        // taxesArray,
        time: this.computedServicesTime,
        branch_timezone: this.$store.state.branchData?.timezone,
      }
      try {
        const { status, data } = await httpClient.post('offer/delete-position', obj)
        if (status === 200 && data?.success) {
          // this.positionToDelete.status = 0
          this.positionToDelete.isNew = false
          this.positionToDelete.isUpdated = false
          this.positionToDelete.deleted_at = +new Date() / 1000
          this.positionToDelete.deletedBy = {
            first_name: this.$store.state.user.first_name,
            last_name: this.$store.state.user.last_name,
          }
          this.closeConfirmPositionDeleteModal()
          this.$toast.add({severity:'success', detail: this.$t('Data deleted'), life: this.settings.toastLife})
          // this.deletedPositions = true
          this.$emit('update-items', this.itemData.id)

          this.sendUpdateOfferWebSocket(this.itemData.id)
        } else if (data?.already_deleted) {
          this.$toast.add({severity:'error', summary: this.$t('Error'), detail: 'Position already deleted', life: this.settings.toastLife});
        } else if (data?.error) {
          this.positionToDelete.status = 1
          this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
        } else {
          this.positionToDelete.status = 1
        }
      } catch(err) {
        this.positionToDelete.status = 1
        this.showError(err)
      } finally {
        if (this.positionToDelete.type === 1) {
          this.updateConsumable()
        }
        this.updateSummary()
        this.modalDataIsLoading = false
      }
    },
    async saveItem() {
      // this.disableSaveButton = true
      this.submitted = true

      if (!this.validUntilDateIsValid ||
          !this.itemData.valid_until ||
          !this.itemData.selectedState ||
          (this.isAnyActiveServicePosition && !this.selectedCustomer) ||
          // !this.selectedResponsible ||
          // !this.itemData.name ||
          (this.useConsumables && this.consumable && (!this.consumable.name || !this.consumable.selectedTax)) ||
          (this.itemData.customer_comment && this.itemData.customer_comment.length > this.customerCommentMaxChars) ||
          (this.itemData.staff_comment && this.itemData.staff_comment.length > this.internalCommentMaxChars) ||
          (this.itemData.additional_information && this.itemData.additional_information.length > this.offerAdditionalInformationMaxChars)) {
        // this.disableSaveButton = false
        // this.dataIsSending = false
        return false
      }

      let wrongInputs = false
      let positionSellingPriceIsLow = false
      // let taxIsInvalid = false

      this.positions?.filter(position => position.status)?.forEach(position => {
        // if (this.positions && this.positions.length && (!position.name || !position.sell_price || !position.qty  || !position.selectedTax)) {
        if (!position.name || !position.sell_price || !position.qty  || !position.selectedTax) {
          wrongInputs = true
        }
        if (position.type === 1 && !position.selectedService) {
          wrongInputs = true
        }
        if (position.type === 2 && !position.selectedSupplier) {
          wrongInputs = true
        }
        if (position.type === 3 && !position.selectedWarehouseItem) {
          wrongInputs = true
        }
        if (position.sell_price < position.purchase_price && !this.ignoreSellingPriceIsTooLow) {
          positionSellingPriceIsLow = true
        }
        // if (position.selectedTax.valid_until) {
        //   if (!this.checkTaxIsValid(position)) {
        //     console.log(111)
        //     taxIsInvalid = true
        //     position.taxIsInvalid = true
        //   } else {
        //     console.log(222)
        //     position.taxIsInvalid = false
        //   }
        // }
      })

      // if (this.consumable) {
      //   if (this.consumable.selectedTax.valid_until && this.consumable.selectedTax.valid_until < this.itemData.created_at) {
      //     taxIsInvalid = checkTaxIsValid
      //   }
      // }

      // if (this.useConsumables && this.consumable && (!this.consumable.name || !this.consumable.selectedTax)) {
      //   wrongInputs = true
      // }

      if (wrongInputs) {
        return false
      }
      // if (taxIsInvalid) {
      //   this.$toast.add({severity:'error', summary: this.$t('Error'), detail: 'This tax cannot be applied', life: settings.toastLife})
      //   return false
      // }
      if (positionSellingPriceIsLow) {
        this.openConfirmSellingPriceIsTooLowModal()
        return false
      }

      this.disableSaveButton = true
      this.dataIsSending = true

      const offer = {
        id: this.itemData.id ?? null,
        user_id: this.selectedCustomer ? +this.selectedCustomer.id : null,
        car_id: this.selectedCar ? this.selectedCar.id : null,
        car_user_id: this.selectedCar ? this.selectedCar.user_id : null,
        state: this.itemData.selectedState ? this.itemData.selectedState.num : null,
        name: this.itemData.name ? this.itemData.name.trim() : null,
        use_consumables: this.useConsumables,
        responsible_id: this.selectedResponsible?.id,
        branch_id: this.$store.state.branchData ? this.$store.state.branchData.id : null,
        customer_comment: this.itemData.customer_comment ? this.itemData.customer_comment.trim() : null,
        staff_comment: this.itemData.staff_comment ? this.itemData.staff_comment.trim() : null,
        additional_information: this.itemData.additional_information ? this.itemData.additional_information.trim() : null,
        is_warning: this.itemData.is_warning ? 1 : 0,
        sum: this.summary.sum ?? null,
        tax: this.summary.tax ?? null,
        grand_total: this.summary.grand_total ?? null,
        orderId: this.itemData.orderId ?? null,
        time: this.computedServicesTime,
        branch_timezone: this.$store.state.branchData?.timezone,
        newPositions: this.generateNewPositionsData(),
        updatedPositions: this.generateUpdatedPositionsData(),
        // taxesArray:  this.itemData.selectedState?.num !== 1 ? this.generateTaxesArray() : null,
        // valid_until: this.itemData.valid_until ? +this.itemData.valid_until / 1000 : null
        valid_until: this.itemData.valid_until ? +new Date(this.itemData.valid_until / 1000) : +new Date(Date.now() / 1000),
      }

      // if (this.selectedCar?.id !== this.originalSelectedCarId) {
      //   offer.carIsChanged = true
      // }

      // if (this.itemData.valid_until) {
      //   const date = new Date(this.itemData.valid_until)
      //   const year = date.getFullYear()
      //   const month = date.getMonth()
      //   const day = date.getDate()
      //   const validUntil = +new Date(year, month, day)
      //   offer.valid_until = validUntil / 1000
      // }

      if (this.useConsumables && this.consumable) {
        offer.consumable = {
          id: this.itemData.id ?? null,
          name: this.consumable.name,
          qty: this.consumable.qty,
          purchase_price: this.consumable.purchase_price,
          sell_price: this.consumable.sell_price,
          discount: this.consumable.discount,
          discount_type: this.consumable.discount_type,
          discount_sum: this.consumable.discount_sum,
          tax_id: this.consumable.selectedTax.id,
          tax_value: this.consumable.tax_value,
          tax_sum: this.consumable.tax_sum,
          sum: this.consumable.sum,
          total: this.consumable.total
        }
      }

      if (this.updateMasters || !this.itemData.id) {
        offer.masters = this.selectedMasters ? this.selectedMasters.map(master => master.id) : null
      } else {
        offer.masters = null
      }

      // const updatedPositions = this.positions.filter(position => position.isUpdated)
      // if (updatedPositions && updatedPositions.length) {
      //   offer.updatedPositions = updatedPositions
      // }

      // offer.updatedPositions = this.positions?.filter(position => position.isUpdated)
      // offer.newPositions = this.positions?.filter(position => position.isNew)

      if (this.itemData.id) {
        if (this.updateMasters && !this.selectedMasters?.length) {
          offer.removeAllMasters = true
        }

        try {
          const { status, data } = await httpClient.post(`offer/update`, offer)
          // console.log(data)
          if (status === 200 && data?.success) {
            // this.$emit('update-items', this.itemData.id)
            this.$emit('update-item', this.itemData.id)
            this.$toast.add({severity:'success', detail: this.$t('Data updated'), life: this.settings.toastLife});
            this.close()

            this.sendUpdateOfferWebSocket(this.itemData.id)
          } else if (data?.error) {
            this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.disableSaveButton = false
          this.dataIsSending = false
          this.ignoreSellingPriceIsTooLow = false
        }
      } else {
        try {
          const { status, data } = await httpClient.post(`offer/create`, offer)
          if (status === 201 && data?.success) {
            this.itemData.id = data.id
            offer.id = data.id
            this.itemData.isNew = true
            this.$emit('update-items', this.itemData.id)
            this.$emit('update-item', this.itemData.id)
            this.$toast.add({severity:'success', detail: this.$t('Data saved'), life: this.settings.toastLife});
            this.close()

            if (this.$store.state.webSocket?.readyState === 1) {
              this.$store.state.webSocket.send(JSON.stringify({'action' : 'createOffer'}))
            }
          } else if (data?.error) {
            this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.disableSaveButton = false
          this.dataIsSending = false
          this.ignoreSellingPriceIsTooLow = false
        }
      }
    },
  },
  computed: {
    computedWindowSize() {
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: 0, width: '95%', marginTop: this.marginTopSpace + 10 + 'px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '85%', marginTop: this.marginTopSpace + 15 + 'px'}
      } else {
        return {position: 'absolute', top: 0, width: '90%', maxWidth: '1600px', marginTop: this.marginTopSpace + 'px'}
      }
    },
  },

}
</script>

<style scoped lang="scss">
.p-button-text {
  width: auto;
}

.radio-button-label {
  padding: 6px;
  margin-left: 0;
  cursor: pointer;
}

.dropdown-wrapper {
  display: flex;
}

.dropdown__select {
  width: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.dropdown__select--border-radius {
  width: 100%;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.dropdown__select--one-button-width {
  width: calc(100% - 2.357rem);
}

.dropdown__select--two-buttons-width {
  width: calc(100% - (2.357rem + 2.357rem));
}

.dropdown__buttons {
  display: flex;
}

.dropdown__edit-button {
  width: 2.357rem;
  border-radius: 0;
}

.dropdown__edit-button--rounded {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.dropdown__create-button{
  width: 2.357rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.table-wrapper {
  overflow-y: auto;
}

//.datatable > td {
//  padding: 0 !important;
//}

.positions-table {
  width: 100%;
  border-collapse: collapse;
  .table-body__name-input--first {
    min-width: 100px;
    width: 10vw;
  }
  .table-body__name-input--code {
    min-width: 100px;
    width: 10vw;
  }
  .table-body__name-input--name {
    min-width: 130px;
    width: 15vw;
  }
  .table-body__qty-input {
    min-width: 75px;
  }
  .table-body__purchase-input {
    min-width: 50px;
  }
  .table-body__sell-input {
    min-width: 50px;
  }
  .table-body__discount-input {
    min-width: 50px;
  }
  .table-body__sum-input {
    min-width: 65px;
  }
  .table-body__tax-input {
    min-width: 60px;
    width: 5vw;
  }
  .table-body__total-input {
    min-width: 65px;
  }

  .table-summary__price {
    width: 90px;
  }
}

//td {
//  //padding: 6px !important;
//  background-color: red;
//}

.position-icon {
  padding: 4px 3px;
  display: flex;
  justify-content: center;
  &:hover {
    opacity: 0.7;
  }
  i {
    font-size: 1.1em;
  }
}
</style>